import { isSporsmalBesvart, isSeksjonBesvart, isNotatBesvart } from 'logic/validation';
import { registerActionForAutoSave, UPDATE_SPORSMAL_UTTALELSE_INIT } from './autoSaveDuck';

const SET_INGEN_MERKNADER = 'uttalelse/SET_INGEN_MERKNADER';

export const UPDATE_SPORSMAL_UTTALELSE = 'uttalelse/UPDATE_SPORSMAL_UTTALELSE';
export const UPDATE_SEKSJON_UTTALELSE = 'uttalelse/UPDATE_SEKSJON_UTTALELSE';
export const UPDATE_NOTAT_UTTALELSE = 'uttalelse/UPDATE_NOTAT_UTTALELSE';

const hasFeedback = (uttalelser, isBesvart) => uttalelser && Object.values(uttalelser).filter((uttalelse) => isBesvart(uttalelse)).length > 0;

const reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_INGEN_MERKNADER:
      return { ...state, ingenMerknader: action.payload };

    case UPDATE_SPORSMAL_UTTALELSE_INIT:
    case UPDATE_SPORSMAL_UTTALELSE:
      return { ...state, sporsmal: { ...state.sporsmal, [action.payload.sporsmalId]: { ...state.sporsmal[action.payload.sporsmalId], ...action.payload.uttalelse } } };

    case UPDATE_SEKSJON_UTTALELSE:
      return { ...state, seksjoner: { ...state.seksjoner, [action.payload.seksjonId]: { ...state.seksjoner[action.payload.seksjonId], ...action.payload.uttalelse } } };

    case UPDATE_NOTAT_UTTALELSE:
      return { ...state, notater: { ...state.notater, [action.payload.notatId]: { ...state.notater[action.payload.notatId], ...action.payload.uttalelse } } };

    default:
      return { ...state, hasFeedback: hasFeedback(state.sporsmal, isSporsmalBesvart) || hasFeedback(state.seksjoner, isSeksjonBesvart) || hasFeedback(state.notater, isNotatBesvart) };
  }
};

// actions

export const setIngenMerknader = (value) => {
  return {
    type: SET_INGEN_MERKNADER,
    payload: value
  };
};

export const updateSporsmalUttalelse = (sporsmalId, uttalelse, sporsmal, oldState) => {
  return {
    type: UPDATE_SPORSMAL_UTTALELSE,
    payload: {
      sporsmalId,
      uttalelse,
      sporsmal,
      oldState
    }
  };
};

export const updateSporsmalUttalelseInit = (sporsmalId, uttalelse, sporsmal, oldState) => {
  return {
    type: UPDATE_SPORSMAL_UTTALELSE_INIT,
    payload: {
      sporsmalId,
      uttalelse,
      sporsmal,
      oldState
    }
  };
};

export const updateSeksjonUttalelse = (seksjonId, uttalelse, seksjon, oldState) => {
  return {
    type: UPDATE_SEKSJON_UTTALELSE,
    payload: {
      seksjonId,
      uttalelse,
      seksjon,
      oldState
    }
  };
};

export const updateNotatUttalelse = (notatId, uttalelse, notat, oldState) => {
  return {
    type: UPDATE_NOTAT_UTTALELSE,
    payload: {
      notatId,
      uttalelse,
      notat,
      oldState
    }
  };
};

// selectors
export const getSporsmalUttalelse = (sporsmalId, uttalelse) => {
  return uttalelse.sporsmal[sporsmalId];
};

export const getSeksjonUttalelse = (seksjonId, uttalelse) => {
  return uttalelse.seksjoner[seksjonId];
};

export const getNotatUttalelse = (notatId, uttalelse) => {
  return uttalelse.notater[notatId];
};

registerActionForAutoSave(UPDATE_SPORSMAL_UTTALELSE);
registerActionForAutoSave(UPDATE_SEKSJON_UTTALELSE);
registerActionForAutoSave(UPDATE_NOTAT_UTTALELSE);

export default reducer;
