export const downloadFile = (url, method = 'get', values) => {
  const form = document.createElement('form');
  form.setAttribute('method', method);
  form.setAttribute('target', '_blank');
  form.setAttribute('rel', 'noopener noreferrer');
  form.setAttribute('action', url);

  if (values) {
    Object.keys(values).forEach((name) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', name);
      input.setAttribute('value', values[name]);
      form.appendChild(input);
    });
  }

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};
