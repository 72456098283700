import { getDefaultLocaleFromMalforms } from "../utils/localeUtils";

const flatten = (arr) => arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatten(val) : val), []);

const initial = window.initialState;
export const urls = window.hoeringUrls;
export const froala = window.froalaLicense;
export const sentry = window.sentryId;
export const { texts, mode, footer, personvernerklaering, institusjonLongName, enums, fylker } = initial;

const getSporsmalFromHoering = (hoering) => flatten(hoering.notat.map((n) => n.seksjons.map((s) => s.sporsmals)));
const getSeksjonerFromHoering = (hoering) => flatten(hoering.notat.map((n) => n.seksjons));
const getNotaterFromHoering = (hoering) => flatten(hoering.notat);

const getSporsmalUttalelser = (sporsmals) =>
  sporsmals.reduce((uttalelser, sporsmal) => {
    const returnVal = {};
    if (sporsmal.positivNegativ) {
      returnVal.positivNegativSvar = { value: sporsmal.positivNegativ.uttalelse };
    }
    if (sporsmal.jaNei) {
      returnVal.jaNeiSvar = { value: sporsmal.jaNei.uttalelse };
    }
    if (sporsmal.enigUenig) {
      returnVal.enigUenigSvar = { value: sporsmal.enigUenig.uttalelse };
    }
    if (sporsmal.heltall) {
      returnVal.heltallSvar = { value: sporsmal.heltall.uttalelse };
    }
    if (sporsmal.kortTekst) {
      returnVal.kortTekstSvar = { value: sporsmal.kortTekst.uttalelse || "" };
    }
    if (sporsmal.dato) {
      returnVal.datoSvar = { value: sporsmal.dato.uttalelse, dates: {} };
    }
    if (sporsmal.fylkeKommune) {
      returnVal.fylkeKommuneSvar = { value: sporsmal.fylkeKommune.uttalelse ?? [] };
    }
    if (sporsmal.kommentar) {
      returnVal.kommentar = { value: sporsmal.kommentar.uttalelse || "" };
    }
    if (sporsmal.vedlegg) {
      returnVal.vedlegg = sporsmal.vedlegg.vedlegg;
    }
    let egendefinerteSvar = [];
    const getEgendefinerte = (alts) =>
      alts.filter((alt) => alt.erEgendefinert && alt.tittel).map((alt) => ({ id: alt.id, tittel: alt.egendefinertSvar ?? "" }));
    if (sporsmal.ettValg) {
      returnVal.ettValgId = {
        value: sporsmal.ettValg.svarAlternativ
          .filter((alt) => alt.selected)
          .map((alt) => alt.id)
          .find(() => true),
      };
      egendefinerteSvar = egendefinerteSvar.concat(getEgendefinerte(sporsmal.ettValg.svarAlternativ));
    }
    if (sporsmal.flereValg) {
      returnVal.flereValgIds = sporsmal.flereValg.svarAlternativ.filter((alt) => alt.selected).map((alt) => alt.id);
      egendefinerteSvar = egendefinerteSvar.concat(getEgendefinerte(sporsmal.flereValg.svarAlternativ));
    }
    if (egendefinerteSvar.length > 0) returnVal.egendefinertSvar = egendefinerteSvar.reduce((agg, v) => ({ ...agg, [v.id]: v.tittel }), {});
    return { ...uttalelser, [sporsmal.id]: returnVal };
  }, {});

const getSeksjonerUttalelser = (seksjoner) =>
  seksjoner.reduce((uttalelser, seksjon) => {
    const returnVal = {};
    if (seksjon.kanHaMarkering) {
      returnVal.markeringKommentarer = seksjon.markeringKommentarer;
    }
    return { ...uttalelser, [seksjon.id]: returnVal };
  }, {});

const getNotaterUttalelser = (notater) =>
  notater.reduce((uttalelser, notat) => {
    const returnVal = {};
    if (notat.kanHaGenerellKommentar) {
      returnVal.kommentar = { value: notat.uttalelse.generellKommentar || "" };
    }
    if (notat.kanHaGenerellHoldning) {
      returnVal.positivNegativSvar = { value: notat.uttalelse.generellHoldning };
    }
    return { ...uttalelser, [notat.id]: returnVal };
  }, {});

const getNotifications = ({ notifications }) => {
  if (notifications.length === 0) return undefined;

  return {
    nextId: notifications.length,
    notifications,
  };
};

const getDefaultLocale = (hoering) => {
  if (initial.hoering.malforms.includes(",")) {
    return hoering.userMålform ?? getDefaultLocaleFromMalforms(initial.hoering.malforms);
  }
  return initial.hoering.malforms;
};

const transform = () => {
  if (!initial.hoering) return { ...initial };

  const uttalelse = {
    ingenMerknader: initial.hoering.ingenMerknader.uttalelse,
    sporsmal: getSporsmalUttalelser(getSporsmalFromHoering(initial.hoering)),
    seksjoner: getSeksjonerUttalelser(getSeksjonerFromHoering(initial.hoering)),
    notater: getNotaterUttalelser(getNotaterFromHoering(initial.hoering)),
  };

  return {
    ...initial,
    notifications: getNotifications(initial.hoering),
    uttalelse,
    initialUttalelse: uttalelse,
    sporsmal: getSporsmalFromHoering(initial.hoering).reduce(
      (sporsmals, sporsmal) => ({
        ...sporsmals,
        [sporsmal.id]: sporsmal,
      }),
      {}
    ),
    seksjon: getSeksjonerFromHoering(initial.hoering).reduce(
      (seksjons, seksjon) => ({
        ...seksjons,
        [seksjon.id]: seksjon,
      }),
      {}
    ),
    locale: getDefaultLocale(initial.hoering),
    notat: getNotaterFromHoering(initial.hoering).reduce((notats, notat) => ({ ...notats, [notat.id]: notat }), {}),
  };
};

export default transform;
