import PropTypes from "prop-types";
import { Popin } from "@utdanningsdirektoratet/popin";
import { DetailedList, DetailedListRow, DetailedListCell } from "@utdanningsdirektoratet/detailedlist";
import { FormattedMessage } from "react-intl";
import compareDesc from "date-fns/compareDesc";
import { getUtkasts } from "utils/localStorageUtils";
import { parseDate, formatDate } from "@utdanningsdirektoratet/utils/date";
import { UTKAST_FORMAT } from "constants/dateFormats";

export const utkastPopinId = "utkastPopinId";

const UtkastPopin = ({ hoeringId }) => {
  const utkasts = getUtkasts(hoeringId);

  const search = new URLSearchParams(window.location.search);

  return (
    <Popin
      id={utkastPopinId}
      title={<FormattedMessage id="utkastPopin.title" />}
      disableInitialFocus
      cancelButton={<FormattedMessage id="utkastPopin.nyttUtkast" />}
    >
      <DetailedList>
        {utkasts
          .sort((a, b) => compareDesc(parseDate(a.endret), parseDate(b.endret)))
          .map((utkast) => (
            <DetailedListRow key={utkast.eksternId} grey>
              {({ className }) => {
                search.set("utkast", utkast.eksternId);
                return (
                  <a className={className} href={`?${search.toString()}`}>
                    <DetailedListCell>
                      <FormattedMessage id="utkastPopin.utkastSistLagret" />
                    </DetailedListCell>
                    <DetailedListCell size="175px" right>
                      {formatDate(utkast.endret, UTKAST_FORMAT)}
                    </DetailedListCell>
                  </a>
                );
              }}
            </DetailedListRow>
          ))}
      </DetailedList>
    </Popin>
  );
};

UtkastPopin.propTypes = {
  hoeringId: PropTypes.number.isRequired,
};

export default UtkastPopin;
