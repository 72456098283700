import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { notificationShape } from "utils/PropTypes";
import { Notifications } from "@utdanningsdirektoratet/notifications";
import { flatten } from "utils/objectUtils";

import { texts } from "logic/initialState";
import { getLocaleCode } from "../utils/localeUtils";

export default (ComposedComponent) => {
  const App = (props) => {
    const notifications = useSelector((state) => state.notifications.notifications);
    const locale = useSelector((state) => state.locale);

    return (
      <IntlProvider locale={getLocaleCode(locale)} messages={flatten(texts[getLocaleCode(locale)])} onError={() => {}}>
        <div lang={`${getLocaleCode(locale)}-NO`}>
          <ComposedComponent {...props} />
          <Notifications notifications={notifications} closeOnClick />
        </div>
      </IntlProvider>
    );
  };

  App.propTypes = {
    locale: PropTypes.string,
    notifications: PropTypes.arrayOf(notificationShape),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  };

  return App;
};
