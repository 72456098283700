import { useMemo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useIntl, FormattedMessage } from "react-intl";
import { Icon } from "@utdanningsdirektoratet/icon";
import { Button } from "@utdanningsdirektoratet/button";
import { RichText, RichTextView, ConfigBuilder } from "@utdanningsdirektoratet/richtext";
import { TrashableRichText } from "@utdanningsdirektoratet/richtext/TrashableRichText";
import { useDebounce, useToggle } from "@utdanningsdirektoratet/helpers";
import { Tooltip } from "@utdanningsdirektoratet/tooltip";
import { validationErrorsShape } from "utils/PropTypes";
import { froala } from "logic/initialState";

const Kommentar = (props) => {
  const { id, readonly, obligatorisk, forhandsvisning, updateUttalelse, generell, validationErrors, maxAntallTegn } = props;
  const intl = useIntl();

  const config = useMemo(() => {
    return new ConfigBuilder()
      .withToolbar((toolbar) => {
        return toolbar.filter((x) => x !== "underline" && x !== "paragraphFormat" && x !== "insertTable");
      })
      .withPlugins((plugins) => {
        return maxAntallTegn?.enabled && maxAntallTegn?.antallTegn ? [...plugins, "charCounter"] : plugins;
      })
      .build();
  }, [maxAntallTegn]);

  const richTextOptions = {
    heightMin: 150,
    pasteDeniedTags: ["table", "thead", "tbody", "th", "tr", "td", "caption"],
    ...(maxAntallTegn?.enabled && maxAntallTegn?.antallTegn ? { charCounterMax: maxAntallTegn.antallTegn } : {}),
  };

  const kommentar = props.kommentar.value;
  const onChangeWrapped = (value) => updateUttalelse(id, { kommentar: { value } });

  let buttonText = "formLabels.leggTilKommentar";
  let label = "formLabels.kommentar";
  let ingenKommentar = "validation.ingenKommentar";
  if (generell) {
    buttonText = "formLabels.leggTilGenerellKommentar";
    label = "formLabels.generellKommentar";
    ingenKommentar = "validation.ingenGenerellKommentar";
  }

  const [value, onChange] = useDebounce(onChangeWrapped, kommentar, 500);
  const [show, toggle, autoFocus] = useToggle(!!kommentar || obligatorisk);

  if (!forhandsvisning && readonly) {
    if (!kommentar) {
      return (
        <div className="Sporsmal-body Sporsmal-body--italic">
          <FormattedMessage id={ingenKommentar} />
        </div>
      );
    }
    return (
      <div className="Sporsmal-body">
        <label // eslint-disable-line jsx-a11y/label-has-associated-control
          className="RichText-label"
        >
          <FormattedMessage id={label} />
        </label>
        <RichTextView id={id} html={kommentar} />
      </div>
    );
  }

  const error = validationErrors.length > 0;
  const validationErrorMessagesMalform = validationErrors.map((err) => intl.formatMessage(err.text)).join("\n");

  const buttonClass = classnames({
    "Sporsmal-button": true,
    "Sporsmal-button--error": error,
  });
  const onTrash = () => {
    onChange("");
    toggle();
  };

  const richtextProps = {
    disabled: readonly,
    value,
    onChange,
    error,
    license: froala,
    icon: "warning",
    message: validationErrorMessagesMalform,
    ariaLabel: intl.formatMessage({ id: label }),
    autoFocus,
    config,
    lazy: !!kommentar || obligatorisk,
    options: richTextOptions,
  };

  if (obligatorisk) {
    return (
      <div className="Sporsmal-body">
        <RichText {...richtextProps} />
      </div>
    );
  }

  return (
    <div className="Sporsmal-body">
      {show ? (
        <TrashableRichText
          {...richtextProps}
          label={intl.formatMessage({ id: label })}
          onTrash={onTrash}
          trashTooltip={intl.formatMessage({ id: "formLabels.slettKommentar" })}
        />
      ) : (
        <Tooltip className="u--block" message={validationErrorMessagesMalform} position="top" align="left" disabled={!error} alwaysShow>
          <Button className={buttonClass} onClick={toggle} disabled={readonly}>
            <Icon icon="speech bubble" type="small" placement="before" />
            <FormattedMessage id={buttonText} />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

Kommentar.propTypes = {
  id: PropTypes.number.isRequired,
  updateUttalelse: PropTypes.func.isRequired,
  kommentar: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  readonly: PropTypes.bool.isRequired,
  forhandsvisning: PropTypes.bool.isRequired,
  obligatorisk: PropTypes.bool,
  validationErrors: validationErrorsShape.isRequired,
  generell: PropTypes.bool,
  maxAntallTegn: PropTypes.shape({
    enabled: PropTypes.bool,
    antallTegn: PropTypes.number,
  }),
};

export default Kommentar;
