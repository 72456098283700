import { isNotatBesvart } from 'logic/validation';
import { getKommentar } from './vmUtils';

export default (notatUttalelser, initial) => Object.keys(notatUttalelser)
  .map(id => ({ id, utt: notatUttalelser[id], initialUtt: initial[id].utt }))
  .filter(u => isNotatBesvart(u.utt) || !isNotatBesvart(u.initialUtt))
  .map(u => ({
    id: u.id,
    generellHoldning: u.utt.positivNegativSvar ? u.utt.positivNegativSvar.value : undefined,
    generellKommentar: getKommentar(u.utt, u.initialUtt)
  }));
