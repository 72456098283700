import PropTypes from "prop-types";
import classnames from "classnames";
import { Tooltip } from "@utdanningsdirektoratet/tooltip";
import { validationErrorsShape } from "utils/PropTypes";
import { useIntl, FormattedMessage } from "react-intl";
import SvarAlternativ, { TYPE_RADIO } from "./SvarAlternativ";
import Obligatorisk from "./Obligatorisk";

const trimFalsyProperties = (obj) =>
  Object.keys(obj)
    .filter((key) => obj[key])
    .reduce((values, key) => {
      return { ...values, [key]: obj[key] };
    }, {});

const EttValg = ({
  ettValg,
  uttalelse,
  sporsmalId,
  updateSporsmalUttalelse,
  readonly,
  obligatorisk,
  forhandsvisning,
  validationErrors,
}) => {
  const intl = useIntl();
  const { svarAlternativ } = ettValg;

  const ettvalgId = uttalelse.ettValgId.value;
  if (!forhandsvisning && readonly && !ettvalgId) {
    return (
      <div className="Sporsmal-body Sporsmal-body--bold">
        <div>
          <FormattedMessage id="validation.velgEttAlternativ" />
        </div>
        <em className="u--italic">
          <FormattedMessage id="validation.intetAlternativerValgt" />
        </em>
      </div>
    );
  }

  const updateValue =
    (id) =>
    ({ selected, egendefinert }) => {
      const ettValgId = selected ? { value: id } : {};
      const egendefinertSvar = trimFalsyProperties({ ...uttalelse.egendefinertSvar, [id]: egendefinert });
      return updateSporsmalUttalelse(sporsmalId, { ettValgId, egendefinertSvar });
    };

  const radios = svarAlternativ.map((alternativ) => {
    const { id } = alternativ;

    const isChecked = id === ettvalgId;
    const svar = uttalelse && uttalelse.egendefinertSvar && uttalelse.egendefinertSvar[id];

    return (
      <SvarAlternativ
        key={id}
        id={id}
        type={TYPE_RADIO}
        egendefinertSvar={svar !== undefined ? svar : ""}
        obligatorisk={obligatorisk}
        alternativ={alternativ}
        svarAlternativ={svarAlternativ}
        errors={validationErrors}
        disabled={readonly}
        selected={isChecked}
        onChange={({ selected, egendefinert }) => updateValue(id)({ selected, egendefinert })}
      />
    );
  });

  const validationErrorMessagesMalform = validationErrors.filter((e) => !e.alternativId).map((e) => intl.formatMessage(e.text));

  const radioClass = classnames({
    "u--marginTop1": radios.length > 1,
  });

  return (
    <div className="Sporsmal-body">
      <Tooltip
        className="u--block"
        message={validationErrorMessagesMalform}
        align="left"
        position="bottom"
        disabled={validationErrors.filter((e) => !e.alternativId).length === 0}
        alwaysShow
      >
        {radios.length > 1 ? (
          <Obligatorisk isObligatorisk={obligatorisk}>
            <FormattedMessage id="validation.velgEttAlternativ" />
          </Obligatorisk>
        ) : null}
        <div className={radioClass}>{radios}</div>
      </Tooltip>
    </div>
  );
};

EttValg.propTypes = {
  ettValg: PropTypes.object.isRequired,
  uttalelse: PropTypes.shape({
    ettvalgId: PropTypes.number,
    egendefinertSvar: PropTypes.object,
  }),
  sporsmalId: PropTypes.number.isRequired,
  updateSporsmalUttalelse: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired,
  obligatorisk: PropTypes.bool,
  forhandsvisning: PropTypes.bool.isRequired,
  validationErrors: validationErrorsShape.isRequired,
};

export default EttValg;
