import PropTypes from "prop-types";
import { CurrencyInput } from "@utdanningsdirektoratet/maskedinput";
import { validationErrorsShape } from "utils/PropTypes";
import { useIntl, FormattedMessage } from "react-intl";
import Obligatorisk from "./Obligatorisk";

const onChange = (e, updateSporsmalUttalelse, sporsmalId) => {
  const value = e ? parseInt(e, 10) : e;
  if (!value || value === 0 || (value >= 0 && value <= 2147483647 && Number.isInteger(value))) {
    updateSporsmalUttalelse(sporsmalId, {
      heltallSvar: { value },
    });
  }
};

const HeltallSvar = ({ readonly, obligatorisk, sporsmalId, updateSporsmalUttalelse, forhandsvisning, heltallSvar, validationErrors }) => {
  const intl = useIntl();
  const error = validationErrors.length > 0;
  const validationErrorMessagesMalform = validationErrors.map((err) => intl.formatMessage(err.text)).join("\n");

  if (!forhandsvisning && readonly) {
    if (heltallSvar.value) {
      return <div className="Sporsmal-body">{heltallSvar.value}</div>;
    }
    return (
      <div className="Sporsmal-body Sporsmal-body--italic">
        <FormattedMessage id="validation.ikkeAngittHeltall" />
      </div>
    );
  }

  return (
    <div className="Sporsmal-body">
      <Obligatorisk isObligatorisk={obligatorisk}>
        <CurrencyInput
          aria-label="Heltall"
          className="Sporsmal-input Sporsmal-heltall"
          error={error}
          message={validationErrorMessagesMalform}
          disabled={readonly}
          value={heltallSvar.value}
          onChange={(e) => onChange(e, updateSporsmalUttalelse, sporsmalId)}
          integerLimit={10}
          allowDecimal={false}
          allowNegative={false}
        />
      </Obligatorisk>
    </div>
  );
};

HeltallSvar.propTypes = {
  sporsmalId: PropTypes.number.isRequired,
  updateSporsmalUttalelse: PropTypes.func.isRequired,
  heltallSvar: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  readonly: PropTypes.bool.isRequired,
  obligatorisk: PropTypes.bool,
  forhandsvisning: PropTypes.bool.isRequired,
  validationErrors: validationErrorsShape.isRequired,
};

export default HeltallSvar;
