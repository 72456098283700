import { newNotification } from '@utdanningsdirektoratet/notifications';

const reducer = (state = {}) => {
  return state;
};

export const newInfoNotification = (titleKey, messageKey) => {
  newNotification('info', titleKey, messageKey);
  return { type: 'NOOP' };
};

export const newSuccessNotification = (titleKey, messageKey) => {
  newNotification('success', titleKey, messageKey);
  return { type: 'NOOP' };
};

export const newErrorNotification = (titleKey, messageKey) => {
  newNotification('error', titleKey, messageKey);
  return { type: 'NOOP' };
};

export default reducer;
