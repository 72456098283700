import type { ValidationProblemDetails } from "../ApiClients";

export function isValidationProblemDetailsObject(error: unknown): error is ValidationProblemDetails {
  return (<ValidationProblemDetails>error).type === "https://tools.ietf.org/html/rfc7231#section-6.5.1";
}

const validationMessage = "Innsendingen hadde en eller fler feil";

export function getValidationMessage(problem: ValidationProblemDetails) {
  const keys = Object.keys(problem.errors ?? {});
  if (keys.length === 1) {
    return problem.errors![keys[0]]?.[0] ?? problem.title ?? validationMessage;
  }
  return problem.title ?? validationMessage;
}
