import PropTypes from "prop-types";
import { Checkbox } from "@utdanningsdirektoratet/checkbox";
import { Tooltip } from "@utdanningsdirektoratet/tooltip";
import { useIntl } from "react-intl";
import { malgruppeShape } from "utils/PropTypes";
import { Element } from "react-scroll";
import FieldName from "../FieldName";

const BekreftRepresentasjon = ({ fields, valgtMalgruppe }) => {
  const intl = useIntl();
  if (!valgtMalgruppe.bekreftRepresentasjon) return null;

  const onChangeWrapped = (e) => {
    const { checked } = e.target;
    fields.bekreftRepresentasjon.onChange(checked);
  };

  const label = valgtMalgruppe.bekreftRepresentasjonTekst[intl.locale]
    ? valgtMalgruppe.bekreftRepresentasjonTekst[intl.locale]
    : intl.formatMessage({ id: "sendInnPopin.bekreftRepresentasjonTekst" }, { 0: valgtMalgruppe.tittel.toLowerCase() });

  return (
    <div className="Grid-12-12 u--marginTop3">
      <Element name={FieldName.BEKREFTREPRESENTASJON} />
      <Tooltip
        className="u--block"
        message={fields.bekreftRepresentasjon.message}
        align="left"
        position="bottom"
        disabled={!fields.bekreftRepresentasjon.error}
        alwaysShow
      >
        <Checkbox
          label={label}
          onChange={onChangeWrapped}
          checked={fields.bekreftRepresentasjon.value}
          error={fields.bekreftRepresentasjon.error}
        />
      </Tooltip>
    </div>
  );
};

BekreftRepresentasjon.propTypes = {
  fields: PropTypes.object.isRequired,
  valgtMalgruppe: malgruppeShape.isRequired,
};

export default BekreftRepresentasjon;
