import PropTypes from 'prop-types';

const DocumentMetadata = ({ title, children }) => {
  document.title = title; // eslint-disable-line
  if (children) {
    return <span>{children}</span>;
  }

  return null;
};

DocumentMetadata.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default DocumentMetadata;
