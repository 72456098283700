import { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import rangy from "rangy";
import MobileDetect from "mobile-detect";
import "rangy/lib/rangy-classapplier";
import "rangy/lib/rangy-highlighter";
import { bindActionCreators } from "redux";
import { v4 as uuidv4 } from "uuid";
import { Icon } from "@utdanningsdirektoratet/icon";
import { Button } from "@utdanningsdirektoratet/button";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { arrayUpdate, arrayRemove } from "utils/immutable";
import { getSeksjonUttalelse } from "ducks/uttalelseDuck";
import { newInfoNotification as newInfoNotificationAction } from "ducks/notificationsDuck";
import assert from "utils/assert";

import Tooltip from "./Tooltip";
import { Målform } from "../../../../../../ApiClients";

const hightlightClass = "MarkerKommentar-comment";
const keyCodeWhitelist = [9, 16, 17, 33, 34, 35, 36, 37, 38, 39, 40];
const keyCodeTagEsc = [9, 27];

const preventDefault = (e) => {
  e.preventDefault();
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
};

class MarkerKommentar extends PureComponent {
  constructor(props) {
    super(props);

    rangy.init();

    this.highlights = [];
    this.state = {
      active: false,
      clicked: false,
      mouseDown: false,
      touch: false,
      keyboard: true,
      current: null,
      comments: props.seksjonUttalelse.markeringKommentarer.map((comment, i) => ({ ...comment, id: i + 1 })),
      id: `seksjonsuttalelse-${props.id}`,
    };
  }

  componentDidMount() {
    document.addEventListener("mouseup", this.onMouseUp);
    document.addEventListener("touchstart", this.onTouchUp);

    this.highlighter = rangy.createHighlighter();
    this.highlighter.addClassApplier(
      rangy.createClassApplier(hightlightClass, {
        elementProperties: {
          onmouseenter: this.onMouseEnter,
          onmouseleave: this.onMouseLeave,
          onclick: this.onClick,
          ontouchstart: this.onClick,
        },
        onElementCreate: (element) => {
          this.highlights = [...this.highlights, element];
        },
      })
    );

    this.highlightComments();
    this.md = new MobileDetect(window.navigator.userAgent);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.comments.length !== prevState.comments.length ||
      this.state.comments.filter((current) => !prevState.comments.find((prev) => prev.tag === current.tag)).length > 0
    ) {
      this.highlightComments();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.onMouseUp);
    document.removeEventListener("touchstart", this.onTouchUp);
  }

  onMouseUp = () => {
    if (!this.md.mobile()) {
      this.clean();
    }
  };

  onTouchUp = () => {
    if (this.state.keyboard) {
      this.setState({ keyboard: false });
    }
    const selection = rangy.getSelection();
    if (selection.isCollapsed) {
      this.setState({ active: false }, this.clean);
    }
  };

  onMouseDown = () => {
    this.setState({ mouseDown: true });
  };

  onMouseEnter = (e) => {
    if (!this.md.mobile()) {
      if (!this.state.current && !this.state.mouseDown) {
        if (this.isImage(e.target)) {
          this.setState({ current: this.getImageId(e) });
        } else {
          const highlight = this.highlighter.getHighlightForElement(e.target);
          if (highlight) {
            this.setState({ current: highlight.id });
          }
        }
      }
    }
  };

  onMouseLeave = () => {
    if (!this.md.mobile()) {
      if (!this.state.clicked && !this.state.mouseDown) {
        this.setState({ current: null });
      }
    }
  };

  onClick = (e) => {
    if (this.props.readonly || this.props.forhandsvisning) return;
    if (this.isImage(e.target)) {
      this.setState({ current: this.getImageId(e), clicked: true });
    } else {
      const highlight = this.highlighter.getHighlightForElement(e.target);
      if (highlight) {
        this.setState({ current: highlight.id, clicked: true });
      }
    }
  };

  onFocus = () => {
    if (this.props.readonly || this.props.forhandsvisning) return;
    this.setState({ active: true });
  };

  onBlur = () => {
    if (this.props.readonly || this.props.forhandsvisning) return;
    this.setState({ active: false });
  };

  onTouchStart = () => {
    if (this.state.keyboard) {
      this.setState({ keyboard: false });
    }
    this.touchTimer = setInterval(() => {
      const selection = rangy.getSelection();
      if (!selection.isCollapsed) {
        this.setState({ active: true, touch: true });
        clearInterval(this.touchTimer);
      }
    }, 100);
  };

  onTouchEnd = () => {
    clearInterval(this.touchTimer);
  };

  onTouchCancel = () => {
    clearInterval(this.touchTimer);
    this.setState({ active: true, touch: true });
  };

  onTextareaBlur = () => {
    if (this.props.readonly || this.props.forhandsvisning) return;
    this.save();
  };

  onTextareaChange = (e) => {
    if (this.props.readonly || this.props.forhandsvisning) return;
    const kommentar = e.target.value;
    const commentIndex = this.state.comments.findIndex((c) => c.bildeId === this.state.current || c.id === this.state.current);
    this.setState((prevState) => ({
      comments: arrayUpdate(prevState.comments, commentIndex, {
        kommentar,
      }),
    }));
  };

  onTextareaClick = () => {
    if (this.props.readonly || this.props.forhandsvisning) return;
    const commentIndex = this.state.comments.findIndex((c) => c.bildeId === this.state.current || c.id === this.state.current);
    this.setState(
      (prevState) => ({
        active: false,
        comments: arrayRemove(prevState.comments, commentIndex),
        current: null,
        clicked: false,
        touch: false,
      }),
      () => {
        this.save();
      }
    );
  };

  onTextareaKeyDown = (e) => {
    if (this.props.readonly || this.props.forhandsvisning) return;
    if (keyCodeTagEsc.indexOf(e.keyCode) !== -1) {
      preventDefault(e);

      const range = rangy.createRange();
      range.selectNode(this.getCurrentContainer());
      const selection = rangy.getSelection();
      selection.setSingleRange(range);
      selection.collapseToEnd();

      this.clean();
    }
  };

  onAddComment = (e) => {
    if (!(this.props.readonly || this.props.forhandsvisning)) {
      preventDefault(e);
      this.handleSelection(e);
    }
  };

  getCurrentContainer = () => {
    return (
      document.getElementById(this.state.current) ||
      this.highlights.find(
        (e) => this.highlighter.getHighlightForElement(e) && this.highlighter.getHighlightForElement(e).id === this.state.current
      )
    );
  };

  getCurrentComment = () => {
    if (!this.state.current) return null;
    return this.state.comments.find((c) => c.bildeId === this.state.current || c.id === this.state.current);
  };

  getImageId = (e) => {
    assert(!!e.target.getAttribute("id"), "Bilde mangler ID");
    return e.target.getAttribute("id");
  };

  isImage = (node) => {
    return node.tagName === "IMG";
  };

  save = () => {
    const comments = this.state.comments.filter((c) => c.kommentar).map((c) => ({ ...c, kommentar: c.kommentar.trim() }));
    this.props.updateSeksjonUttalelse(this.props.id, { markeringKommentarer: comments });
  };

  highlightComments = () => {
    const images = this.wrapper.getElementsByTagName("img");

    // Remove event listeners and classes for all images
    // In case a comment on an image is added or removed
    for (let i = 0; i < images.length; i += 1) {
      images[i].classList.remove(hightlightClass);
      images[i].removeEventListener("mouseenter", this.onMouseEnter);
      images[i].removeEventListener("mouseleave", this.onMouseLeave);
      images[i].removeEventListener("click", this.onClick);
      images[i].removeEventListener("touchstart", this.onClick);
    }

    // Remove all text comment highlights
    this.highlighter.removeAllHighlights();
    this.highlights = [];

    const { id, comments } = this.state;

    if (comments.length > 0) {
      // Create serialized string for current text comments for highlighter
      let serialized = "type:textContent";
      comments
        .filter((c) => !c.bildeId)
        .forEach((comment) => {
          serialized += `|${comment.startIndex}$${comment.endIndex}$${comment.id}$${hightlightClass}$${id}`;
        });

      // Deserialize text comments
      this.highlighter.deserialize(serialized);

      // Add event listeners and classes to commented images
      comments
        .filter((c) => c.bildeId)
        .forEach((comment) => {
          const image = document.getElementById(comment.bildeId);
          image.classList.add(hightlightClass);
          image.addEventListener("mouseenter", this.onMouseEnter);
          image.addEventListener("mouseleave", this.onMouseLeave);
          image.addEventListener("click", this.onClick);
          image.addEventListener("touchstart", this.onClick);
        });
    }

    if (this.state.current) {
      this.forceUpdate();
    }
  };

  handleEvent = (e) => {
    const keyCode = e.which;

    const selection = rangy.getSelection();
    const isNumber = keyCode >= 48 && keyCode <= 57;
    const isLetter = keyCode >= 65 && keyCode <= 90;
    const isF = keyCode >= 112 && keyCode <= 123;

    // Ctrl + X || Ctrl + V || not supported chracter
    if (
      (!(e.metaKey || e.ctrlKey) && keyCodeWhitelist.indexOf(keyCode) === -1 && !isF) ||
      ((e.metaKey || e.ctrlKey) && (keyCode === 86 || keyCode === 88))
    ) {
      preventDefault(e);
    }

    if (e.type === "keydown") {
      const valid = keyCode === 32 || keyCode === 13 || isLetter || isNumber;
      if (valid) {
        if (selection.rangeCount === 0) return;
        const nodes = selection.getRangeAt(0).getNodes();
        if (selection.toString().length > 0) {
          this.handleSelection(e, false, isLetter ? String.fromCharCode(keyCode) : null);
        } else if (selection.focusNode.parentNode.classList.contains(hightlightClass)) {
          selection.focusNode.parentNode.click();
        } else if (nodes.length === 1 && this.isImage(nodes[0])) {
          if (nodes[0].classList.contains(hightlightClass)) {
            nodes[0].click();
          } else {
            this.handleSelection({ ...e, target: nodes[0] }, false, isLetter || isNumber ? String.fromCharCode(keyCode) : null);
          }
        }
      }
    }
  };

  handleWrapperEvent = (e) => {
    if (!this.md.mobile()) {
      this.handleSelection(e);
    }
  };

  handleSelection = (e, doubleClick = false, comment = "") => {
    if (e.target.classList.contains(hightlightClass) || this.props.readonly || this.props.forhandsvisning) {
      this.setState({ mouseDown: false });
      return;
    }

    const isImage = this.isImage(e.target);
    const selection = rangy.getSelection();

    if (isImage || !selection.isCollapsed) {
      preventDefault(e);
    }
    if (!doubleClick && selection.isCollapsed && !isImage) {
      this.clean();
    }

    if (isImage || !selection.isCollapsed) {
      const selectionHtml = selection.toHtml();

      if (isImage || (selectionHtml !== "" && selection.rangeCount === 1)) {
        let bildeId = null;
        let hash = 0;
        let startIndex = 0;
        let endIndex = 0;
        if (!isImage) {
          const range = selection.getRangeAt(0);
          if (!this.wrapper.contains(range.commonAncestorContainer.parentNode)) {
            this.setState({ mouseDown: false });
            return;
          }

          const overlappingNodes = this.highlights.filter((el) => range.intersectsNode(el));
          const overlappingImages = Array.prototype.slice
            .call(this.wrapper.getElementsByTagName("img"), 0)
            .filter((el) => range.intersectsNode(el));
          if (overlappingNodes.length > 0 || overlappingImages.length > 0) {
            this.setState({ mouseDown: false });

            const { newInfoNotification, intl } = this.props;
            newInfoNotification(
              intl.formatMessage({ id: "validation.markeringMislyktes" }),
              intl.formatMessage({ id: "validation.vennligstMarkerTekst" }),
              true
            );
            selection.removeAllRanges();
            return;
          }

          const serializedSelection = this.highlighter.converter.serializeSelection(selection, this.wrapper);
          hash = this.hashCode(range.toString());
          startIndex = serializedSelection[0].characterRange.start;
          endIndex = serializedSelection[0].characterRange.end;
          selection.removeAllRanges();
        } else {
          bildeId = this.getImageId(e);
        }
        if (!doubleClick) {
          const id = this.state.comments.length > 0 ? Math.max(...this.state.comments.map((c) => c.id)) + 1 : 1;
          this.setState((prevState) => ({
            comments: [
              ...prevState.comments.filter((h) => h.kommentar),
              {
                bildeId,
                id,
                hash,
                startIndex,
                endIndex,
                kommentar: comment,
                seksjonId: this.props.id,
                tag: uuidv4(),
                målform: this.props.målform,
              },
            ],
            current: bildeId || id,
            mouseDown: false,
            clicked: true,
          }));
        }
      }
    } else {
      this.setState({ mouseDown: false });
    }
  };

  clean = () => {
    if (this.props.readonly || this.props.forhandsvisning) return;
    this.setState((prevState) => ({
      comments: prevState.comments.filter((h) => h.kommentar),
      current: null,
      clicked: false,
      touch: false,
    }));
  };

  hashCode = (string) => {
    let hash = 0;
    if (string.length === 0) return hash;
    for (let i = 0; i < string.length; i += 1) {
      const chr = string.charCodeAt(i);
      hash = (hash << 5) - hash + chr; // eslint-disable-line no-bitwise
      hash |= 0; // eslint-disable-line no-bitwise
    }
    return hash;
  };

  render() {
    const { children, readonly, forhandsvisning } = this.props;

    const { id, active, current, clicked, touch, keyboard } = this.state;

    const wrapperClass = classnames({
      MarkerKommentar: true,
      "MarkerKommentar--active": (active || current || clicked) && !(readonly || forhandsvisning),
    });

    const infoClass = classnames({
      "MarkerKommentar-info": true,
      "MarkerKommentar-info--active": (active || current || clicked) && !(readonly || forhandsvisning),
    });

    const addCommentClass = classnames({
      "MarkerKommentar-addcomment": true,
      "MarkerKommentar-addcomment--visible": touch && !(current || clicked || readonly || forhandsvisning),
    });

    const currentContainer = this.getCurrentContainer();
    const currentComment = this.getCurrentComment();

    const contentEditable = keyboard && !(readonly || forhandsvisning);

    return (
      <div>
        <div className={infoClass}>
          <span className="MarkerKommentar-info-wrapper">
            <div className="MarkerKommentar-info-tooltip">
              <span className="MarkerKommentar-info-tooltip-inner">
                <FormattedMessage id="formLabels.skrivKommentar" />
              </span>
            </div>
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: this.props.intl.formatMessage({ id: "formLabels.markerOgKommenterHtml" }) }}
            />
          </span>
        </div>
        <div
          id={id}
          className={wrapperClass}
          onMouseUp={this.handleWrapperEvent}
          onTouchStart={this.handleSelection}
          onDoubleClick={(e) => this.handleSelection(e, true)}
          onMouseDown={this.onMouseDown}
          ref={(wrapper) => {
            this.wrapper = wrapper;
          }}
        >
          <div
            className="MarkerKommentar-content"
            onKeyPress={this.handleEvent}
            onKeyUp={this.handleEvent}
            onKeyDown={this.handleEvent}
            onDrag={this.handleEvent}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onTouchStart={this.onTouchStart}
            onTouchEnd={this.onTouchEnd}
            onTouchCancel={this.onTouchCancel}
            onDragOver={preventDefault}
            onDrop={preventDefault}
            onCut={preventDefault}
            onPaste={preventDefault}
            onInput={preventDefault}
            spellCheck="false"
            contentEditable={contentEditable}
            suppressContentEditableWarning
          >
            {children}
          </div>
          <Tooltip
            comment={currentComment}
            container={currentContainer}
            edit={clicked && !readonly && !forhandsvisning}
            onChange={this.onTextareaChange}
            onBlur={this.onTextareaBlur}
            onClick={this.onTextareaClick}
            onKeyDown={this.onTextareaKeyDown}
          />
        </div>
        <Button className={addCommentClass} onTouchStart={this.onAddComment}>
          <Icon icon="edit" type="small" placement="before" />
          <FormattedMessage id="formLabels.leggTilKommentar" />
        </Button>
      </div>
    );
  }
}

MarkerKommentar.propTypes = {
  id: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  seksjonUttalelse: PropTypes.object.isRequired,
  updateSeksjonUttalelse: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired,
  forhandsvisning: PropTypes.bool.isRequired,
  newInfoNotification: PropTypes.func.isRequired,
  intl: PropTypes.object,
  målform: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  const seksjonId = ownProps.id;
  return {
    seksjonUttalelse: getSeksjonUttalelse(seksjonId, state.uttalelse),
    målform: state.locale,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    newInfoNotification: bindActionCreators(newInfoNotificationAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MarkerKommentar));
