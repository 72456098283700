import { Dropdown, DropdownItem } from "@utdanningsdirektoratet/dropdown";
import { MenuPanel } from "@utdanningsdirektoratet/layout";
import { useSelector, useDispatch } from "react-redux";
import { changeLocale } from "ducks/localeDuck";

export const Header = () => {
  const muligeMalforms = useSelector((state) => state.hoering.malforms).split(", ");
  const innsendt = useSelector((state) => state.hoering.innsendt);
  const locale = useSelector((state) => state.locale);
  const institusjonLongName = useSelector((state) => state.institusjonLongName);
  const dispatch = useDispatch();

  if (muligeMalforms.length < 2 || innsendt) {
    return <MenuPanel title={institusjonLongName} external />;
  }

  const malformOptions = muligeMalforms.map((malform) => (
    <DropdownItem closeOnClick selected={malform === locale} onClick={() => dispatch(changeLocale(malform))}>
      {malform}
    </DropdownItem>
  ));

  return (
    <MenuPanel title={institusjonLongName} external>
      <Dropdown lists={[malformOptions]}>{locale}</Dropdown>
    </MenuPanel>
  );
};
