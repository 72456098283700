import { postApi } from 'api/fetchWrapper';
import { newErrorNotification } from 'ducks/notificationsDuck';
import { saveNow } from 'ducks/autoSaveDuck';
import mapVm from 'vm/mapVm';
import { removeUtkast } from 'utils/localStorageUtils';
import { HoeringClient } from '../../../../ApiClients';

const client = new HoeringClient();

export const sendInnForm = (values, uttalelse, hoering, userMalform) => {
  return (dispatch, getState) => {
    saveNow()(dispatch)
      .then((e) => {
        const vm = mapVm(e.payload.eksternId, uttalelse, getState().initialUttalelse, hoering, userMalform, values);
        postApi(client.submitUttalelse(hoering.hoeringId, hoering.innsendingsKode, vm))
          .then((data) => {
            removeUtkast(hoering.hoeringId, e.payload.eksternId);
            window.location = data.url;
          })
          .catch((error) => {
            dispatch(newErrorNotification(error.title, error.message));
          });
      })
      .catch((error) => {
        dispatch(newErrorNotification(error.title, error.message));
      });
  };
};
