import PropTypes from "prop-types";
import { Vedlegg as VedleggComponent } from "@utdanningsdirektoratet/vedlegg";
import { FormattedMessage } from "react-intl";

import { vedleggShape } from "utils/PropTypes";

const Vedlegg = ({ vedlegg, notatVedlegg }) => {
  const notatVedleggList = notatVedlegg.map((v) => {
    return (
      <VedleggComponent key={`notatVedlegg-${v.url}`} id={v.id} className="u--marginBottom0-5" title={v.filNavn} href={v.url} disabled />
    );
  });
  const vedleggList = vedlegg.map((v) => {
    return <VedleggComponent key={`vedlegg-${v.url}`} id={v.id} className="u--marginBottom0-5" title={v.filNavn} href={v.url} disabled />;
  });
  if (notatVedleggList.length === 0 && vedleggList.length === 0) return null;
  return (
    <div className="Page-section u--marginBottom2">
      <div className="u--label">
        <FormattedMessage id="formLabels.vedlegg" />
      </div>
      {notatVedleggList}
      {vedleggList}
    </div>
  );
};

Vedlegg.propTypes = {
  vedlegg: PropTypes.arrayOf(vedleggShape),
  notatVedlegg: PropTypes.arrayOf(vedleggShape),
};

export default Vedlegg;
