import { validationFunctions } from "@utdanningsdirektoratet/form";
import { JaNeiSvarEnum, EnigUenigSvarEnum, PositivNegativEnum, KortSvarTypes } from "constants/serverEnums";
import { svarTypes } from "constants/svarTypes";
import { isPartialDate } from "@utdanningsdirektoratet/utils/date";
import { enums } from "./initialState";

const angittPositivNegativ = (u) => u && u.positivNegativSvar && u.positivNegativSvar.value !== PositivNegativEnum.IkkeAngitt;
const angittJaNei = (u) => u && u.jaNeiSvar && u.jaNeiSvar.value !== JaNeiSvarEnum.IkkeAngitt;
const angittEnigUenig = (u) => u && u.enigUenigSvar && u.enigUenigSvar.value !== EnigUenigSvarEnum.IkkeAngitt;
const angittEttValg = (u) => u && u.ettValgId && u.ettValgId.value;
const angittKommentar = (u) => u && u.kommentar && u.kommentar.value && u.kommentar.value.trim();
const angittFlereValg = (u) => u && u.flereValgIds && u.flereValgIds.length > 0;
const angittVedlegg = (u) => u && u.vedlegg && u.vedlegg.length > 0;
const angittFylke = (u) => u && u.fylkeKommuneSvar && u.fylkeKommuneSvar.value.filter((f) => f.length === 2).length > 0;
const angittKommune = (u) => u && u.fylkeKommuneSvar && u.fylkeKommuneSvar.value.filter((f) => f.length === 4).length > 0;
const angittFylkeKommune = (u) => u && u.fylkeKommuneSvar && u.fylkeKommuneSvar.value.length > 0;
const angittKortTekst = (u) => u && u.kortTekstSvar && u.kortTekstSvar.value && u.kortTekstSvar.value.trim();
const angittDato = (u) => u && u.datoSvar && u.datoSvar.value;
const angittHeltall = (u) => u && u.heltallSvar && (u.heltallSvar.value || u.heltallSvar.value === 0);
const angittMarkerkommentar = (u) => u && u.markeringKommentarer && u.markeringKommentarer.length > 0;
const gyldigHetall = (u) =>
  u &&
  u.heltallSvar &&
  (!u.heltallSvar.value ||
    u.heltallSvar.value === 0 ||
    (u.heltallSvar.value >= 0 && u.heltallSvar.value <= 2147483647 && Number.isInteger(u.heltallSvar.value)));
const gyldigKortTekst = (u, validator) => !angittKortTekst(u) || validator(u.kortTekstSvar.value);

export const validateSporsmal = (sporsmal, uttalelse) => {
  const validationErrors = [];
  const addError = (type, text, alternativId) => validationErrors.push({ type, text, alternativId });
  const hasKommentar = sporsmal.kommentar && angittKommentar(uttalelse);
  if (sporsmal.positivNegativ) {
    if ((sporsmal.positivNegativ.obligatorisk || hasKommentar) && !angittPositivNegativ(uttalelse)) {
      addError(svarTypes.POSITIV_NEGATIV, { id: "validation.oppgiPositivNegativ" });
    }
  }
  if (sporsmal.jaNei) {
    if ((sporsmal.jaNei.obligatorisk || hasKommentar) && !angittJaNei(uttalelse)) {
      addError(svarTypes.JA_NEI, { id: "validation.oppgiJaNei" });
    }
  }
  if (sporsmal.enigUenig) {
    if ((sporsmal.enigUenig.obligatorisk || hasKommentar) && !angittEnigUenig(uttalelse)) {
      addError(svarTypes.ENIG_UENIG, { id: "validation.oppgiEnigUenig" });
    }
  }
  if (sporsmal.ettValg) {
    if (sporsmal.ettValg.obligatorisk && !angittEttValg(uttalelse)) {
      if (sporsmal.ettValg.svarAlternativ.length > 1) {
        addError(svarTypes.ETT_VALG, { id: "validation.oppgiEttValg" });
      } else {
        addError(svarTypes.ETT_VALG, { id: "validation.vennligstKryssAv" });
      }
    }
    sporsmal.ettValg.svarAlternativ
      .filter((alt) => alt.erEgendefinert)
      .map((alt) => alt.id)
      .filter((id) => uttalelse && uttalelse.ettValgId && uttalelse.ettValgId.value === id)
      .filter((id) => !(uttalelse.egendefinertSvar && uttalelse.egendefinertSvar[id] && uttalelse.egendefinertSvar[id].trim()))
      .forEach((id) => addError(svarTypes.ETT_VALG, { id: "validation.annetMaHaTekst" }, id));
  }
  if (sporsmal.flereValg) {
    if (sporsmal.flereValg.obligatorisk && !angittFlereValg(uttalelse)) {
      if (sporsmal.flereValg.svarAlternativ.length > 1) {
        addError(svarTypes.FLERE_VALG, { id: "validation.oppgiEttValg" });
      } else {
        addError(svarTypes.FLERE_VALG, { id: "validation.vennligstKryssAv" });
      }
    }

    sporsmal.flereValg.svarAlternativ
      .filter((alt) => alt.erEgendefinert)
      .map((alt) => alt.id)
      .filter((id) => uttalelse && uttalelse.flereValgIds && uttalelse.flereValgIds.some((f) => f === id))
      .filter((id) => !(uttalelse.egendefinertSvar && uttalelse.egendefinertSvar[id] && uttalelse.egendefinertSvar[id].trim()))
      .forEach((id) => addError(svarTypes.FLERE_VALG, { id: "validation.annetMaHaTekst" }, id));
  }
  if (sporsmal.heltall) {
    if ((sporsmal.heltall.obligatorisk && !angittHeltall(uttalelse)) || !gyldigHetall(uttalelse)) {
      addError(svarTypes.HELTALL, { id: "validation.oppgiHeltall" });
    }
  }
  if (sporsmal.kommentar) {
    if (
      (sporsmal.kommentar.obligatorisk ||
        (angittEnigUenig(uttalelse) &&
          sporsmal.kommentar.obligatoriskKommentarEnigUenig.some((s) => s === uttalelse.enigUenigSvar.value)) ||
        (angittJaNei(uttalelse) && sporsmal.kommentar.obligatoriskKommentarJaNei.some((s) => s === uttalelse.jaNeiSvar.value)) ||
        (angittPositivNegativ(uttalelse) &&
          sporsmal.kommentar.obligatoriskKommentarPositivNegativ.some((s) => s === uttalelse.positivNegativSvar.value))) &&
      !hasKommentar
    ) {
      addError(svarTypes.KOMMENTAR, { id: "validation.oppgiKommentar" });
    }
  }
  if (sporsmal.kortTekst) {
    switch (sporsmal.kortTekst.type) {
      case KortSvarTypes.Kontonr:
        if (
          (sporsmal.kortTekst.obligatorisk && !angittKortTekst(uttalelse)) ||
          !gyldigKortTekst(uttalelse, validationFunctions.kontonummer.validation)
        ) {
          addError(svarTypes.KORTTEKST, { id: "validation.oppgiKortTekstKontonr" });
        }
        break;
      case KortSvarTypes.Orgnr:
        if (
          (sporsmal.kortTekst.obligatorisk && !angittKortTekst(uttalelse)) ||
          !gyldigKortTekst(uttalelse, validationFunctions.organisasjonsnummer.validation)
        ) {
          addError(svarTypes.KORTTEKST, { id: "validation.oppgiKortTekstOrgnr" });
        }
        break;
      case KortSvarTypes.Epost:
        if (
          (sporsmal.kortTekst.obligatorisk && !angittKortTekst(uttalelse)) ||
          !gyldigKortTekst(uttalelse, validationFunctions.email.validation)
        ) {
          addError(svarTypes.KORTTEKST, { id: "validation.oppgiKortTekstEpost" });
        }
        break;
      case KortSvarTypes.Tekst:
      default:
        if (sporsmal.kortTekst.obligatorisk && !angittKortTekst(uttalelse)) {
          addError(svarTypes.KORTTEKST, { id: "validation.oppgiKortTekst" });
        }
        break;
    }
  }
  if (sporsmal.dato) {
    const partialDate = isPartialDate(uttalelse.datoSvar.dates.day, uttalelse.datoSvar.dates.month, uttalelse.datoSvar.dates.year);
    if (partialDate) {
      addError(svarTypes.DATO, { id: "validation.ugyldigDato" });
    }
    if (sporsmal.dato.obligatorisk && !angittDato(uttalelse)) {
      addError(svarTypes.DATO, { id: "validation.oppgiDato" });
    }
  }
  if (sporsmal.fylkeKommune) {
    if (sporsmal.fylkeKommune.obligatorisk && sporsmal.fylkeKommune.type === enums.fylkeKommuneTypes.kommune && !angittKommune(uttalelse)) {
      addError(svarTypes.FYLKEKOMMUNE, { id: "validation.oppgiKommune" });
    }
    if (sporsmal.fylkeKommune.obligatorisk && sporsmal.fylkeKommune.type === enums.fylkeKommuneTypes.fylke && !angittFylke(uttalelse)) {
      addError(svarTypes.FYLKEKOMMUNE, { id: "validation.oppgiFylke" });
    }
    if (
      sporsmal.fylkeKommune.obligatorisk &&
      sporsmal.fylkeKommune.type === enums.fylkeKommuneTypes.fylkeKommune &&
      !angittFylkeKommune(uttalelse)
    ) {
      addError(svarTypes.FYLKEKOMMUNE, { id: "validation.oppgiFylkeKommune" });
    }
  }
  if (sporsmal.vedlegg) {
    if (sporsmal.vedlegg.obligatorisk && !angittVedlegg(uttalelse)) {
      addError(svarTypes.VEDLEGG, { id: "validation.oppgiVedlegg" });
    }
  }
  return validationErrors;
};

export const validateSeksjon = (seksjon, uttalelse) => {
  // eslint-disable-line no-unused-vars
  return [];
};

export const validateNotat = (notat, uttalelse) => {
  const validationErrors = [];
  const addError = (type, text) => validationErrors.push({ type, text });
  const hasKommentar = notat.kanHaGenerellKommentar && angittKommentar(uttalelse);
  if (notat.kanHaGenerellHoldning) {
    if (hasKommentar && !angittPositivNegativ(uttalelse)) {
      addError(svarTypes.POSITIV_NEGATIV, { id: "validation.oppgiPositivNegativ" });
    }
  }
  return validationErrors;
};

export const hasKommentar = angittKommentar;

export const isSporsmalBesvart = (uttalelse) =>
  angittPositivNegativ(uttalelse) ||
  angittJaNei(uttalelse) ||
  angittEnigUenig(uttalelse) ||
  angittEttValg(uttalelse) ||
  angittFlereValg(uttalelse) ||
  angittKommentar(uttalelse) ||
  angittVedlegg(uttalelse) ||
  angittHeltall(uttalelse) ||
  angittKortTekst(uttalelse) ||
  angittDato(uttalelse) ||
  angittFylkeKommune(uttalelse);

export const isSeksjonBesvart = (uttalelse) => angittMarkerkommentar(uttalelse);

export const isNotatBesvart = (uttalelse) => angittPositivNegativ(uttalelse) || angittKommentar(uttalelse);

export const epostValidation = {
  validation: (input) => window.epostRegex.test(input),
  message: { id: "validation.maVaereGyldigEpostadresse" },
  regex: window.epostRegex,
};
