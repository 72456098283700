import PropTypes from "prop-types";
import { Button } from "@utdanningsdirektoratet/button";
import { FormattedMessage } from "react-intl";

const LagreOgGaTilbake = ({ actions, hoering, uttalelse, manuellRegistreringsKode, returnTo }) => {
  return (
    <div className="Page-section Page-section--narrow FooterButtons">
      <div className="Grid-xl-8-12 Grid-md-6-12 Grid-12-12">
        <Button
          className="Button--colored FooterButtons-button FooterButtons-submit"
          onClick={() => actions.lagreOgGaTilbake(hoering.eksternId, uttalelse, hoering, manuellRegistreringsKode, returnTo)}
        >
          <FormattedMessage id="formLabels.lagreOgGaTilbake" />
        </Button>
      </div>
    </div>
  );
};

LagreOgGaTilbake.propTypes = {
  actions: PropTypes.object.isRequired,
  hoering: PropTypes.object.isRequired,
  uttalelse: PropTypes.object.isRequired,
  manuellRegistreringsKode: PropTypes.string,
  returnTo: PropTypes.string,
};

export default LagreOgGaTilbake;
