import PropTypes from "prop-types";
import { formatDate } from "@utdanningsdirektoratet/utils/date";
import { DATE_FORMAT } from "constants/dateFormats";
import { Button, LinkButton } from "@utdanningsdirektoratet/button";
import { Icon } from "@utdanningsdirektoratet/icon";
import { downloadFile } from "utils/urlUtils";
import { FormattedMessage } from "react-intl";
import { urls } from "logic/initialState";
import { SektorEnum } from "../../../../constants/serverEnums";

const InnsendtHeader = ({ show, innsender, eksternId, navnSkjult }) => {
  if (!show) return null;

  return (
    <div className="Header Header--innsendt">
      <div className="Header-buttons Grid-12-12">
        <LinkButton href="?reset=true" className="Header-button">
          <FormattedMessage id="innsendtUttalelse.nyUttalelse" />
        </LinkButton>
        <Button
          className="Header-button"
          onClick={() => downloadFile(eksternId ? `${urls.lastNedUttalelseUrl}/${eksternId}` : urls.lastNedUttalelseUrl)}
        >
          <Icon icon="document" type="small" placement="before" />
          <FormattedMessage id="innsendtUttalelse.lastNedUttalelse" />
        </Button>
      </div>
      <div className="Header-content Grid-12-12">
        <div className="Header-title">
          <FormattedMessage id="innsendtUttalelse.status" />
        </div>
        <div>
          <FormattedMessage id="innsendtUttalelse.innsendtTil" /> <FormattedMessage id="innsendtUttalelse.innsendtTilInstitusjon" />
        </div>
        <div>
          <FormattedMessage id="innsendtUttalelse.uttalelseStatus" />
        </div>
      </div>
      <div className="Grid-12-12 u--marginTop2">
        <div className="Header-content Grid-lg-3-12 Grid-6-12">
          <div className="Header-title">
            <FormattedMessage id="innsendtUttalelse.innsendtAv" />
          </div>
          {navnSkjult ? (
            <em>
              <FormattedMessage id="innsendtUttalelse.skjultAv" />
            </em>
          ) : (
            innsender.innsendtAv
          )}
        </div>
        <div className="Header-content Grid-lg-3-12 Grid-6-12">
          <div className="Header-title">
            <FormattedMessage id="innsendtUttalelse.innsendtDato" />
          </div>
          {formatDate(innsender.innsendtDato, DATE_FORMAT)}
        </div>
        <div className="Header-content Grid-lg-3-12 Grid-6-12">
          <div className="Header-title">
            {innsender.sektor === SektorEnum.EnkeltPerson ? (
              innsender.innsenderRepresentererEtikett
            ) : (
              <FormattedMessage id="innsendtUttalelse.paVegneAv" />
            )}
          </div>
          {navnSkjult && innsender.sektor === SektorEnum.EnkeltPerson ? (
            <em>
              <FormattedMessage id="innsendtUttalelse.skjultAv" />
            </em>
          ) : (
            innsender.paVegneAv
          )}
        </div>
        {innsender.malgruppe ? (
          <div className="Header-content Grid-lg-3-12 Grid-6-12">
            <div className="Header-title">
              <FormattedMessage id="innsendtUttalelse.malgruppe" />
            </div>
            {innsender.malgruppe}
          </div>
        ) : null}
      </div>
    </div>
  );
};

InnsendtHeader.propTypes = {
  show: PropTypes.bool,
  innsender: PropTypes.object.isRequired,
  eksternId: PropTypes.string,
  navnSkjult: PropTypes.bool,
};

InnsendtHeader.defaultProps = {
  show: false,
};

export default InnsendtHeader;
