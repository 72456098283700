import { Målform, Målforms, MålformString } from "../ApiClients";

export const getDefaultLocaleFromMalforms = (horingMalforms: Målforms): Målform => {
  return horingMalforms.includes(Målforms.Bokmål) ? Målform.Bokmål : Målform.Nynorsk;
};

export const getLocaleCode = (locale: Målform) => {
  return locale === Målform.Nynorsk ? "nn" : "nb";
};

export const getTextWithLocale = (texts: MålformString | null | undefined, locale: Målform): string => {
  if (!texts) return "";
  const malformCode = getLocaleCode(locale);
  return texts[malformCode] ?? "";
};
