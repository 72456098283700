import classnames from "classnames";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Icon } from "@utdanningsdirektoratet/icon";
import { STATUS_PENDING, STATUS_COMPLETE } from "ducks/autoSaveDuck";

let content;
const AutoSaveLoader = ({ status }) => {
  if (status === STATUS_PENDING) {
    content = (
      <span>
        <FormattedMessage id="uttalelse.lagrerUtkastSpinner" />
        <div className="AutoSaveLoader-spinner">
          <div className="AutoSaveLoader-spinner-rect AutoSaveLoader-spinner-rect-1" />
          <div className="AutoSaveLoader-spinner-rect AutoSaveLoader-spinner-rect-2" />
          <div className="AutoSaveLoader-spinner-rect AutoSaveLoader-spinner-rect-3" />
          <div className="AutoSaveLoader-spinner-rect AutoSaveLoader-spinner-rect-4" />
          <div className="AutoSaveLoader-spinner-rect AutoSaveLoader-spinner-rect-5" />
        </div>
      </span>
    );
  }
  if (status === STATUS_COMPLETE) {
    content = (
      <span>
        <FormattedMessage id="uttalelse.utkastLagretSpinner" />
        <Icon color="white" type="small" placement="after" icon="check" />
      </span>
    );
  }

  const autoSaveLoaderClass = classnames({
    AutoSaveLoader: true,
    "AutoSaveLoader--visible": status === STATUS_PENDING || status === STATUS_COMPLETE,
  });

  return <div className={autoSaveLoaderClass}>{content}</div>;
};

AutoSaveLoader.propTypes = {
  status: PropTypes.string,
};

const mapStateToProps = ({ autoSave }) => ({ status: autoSave });

export default connect(mapStateToProps)(AutoSaveLoader);
