import PropTypes from "prop-types";
import { Button } from "@utdanningsdirektoratet/button";
import { Icon } from "@utdanningsdirektoratet/icon";
import { Popin, show, close } from "@utdanningsdirektoratet/popin";
import { FormattedMessage, useIntl } from "react-intl";

const popinId = "IngenMerknaderPopin";

const onClickWrapped = (onClick, hasFeedback) => {
  if (hasFeedback) {
    show(popinId);
  } else {
    onClick();
  }
};

const confirmOnClick = (onClick) => {
  close(popinId);
  onClick();
};

const IngenMerknader = ({ kanHaIngenMerknader, onClick, hasFeedback, readonly, harIngenMerknader }) => {
  const intl = useIntl();
  if (!kanHaIngenMerknader || (readonly && !harIngenMerknader)) return null;

  if (readonly) {
    return (
      <div className="Page-gray">
        <div className="Page-section Page-section--narrow">
          <strong>
            <FormattedMessage id="slettMerknaderPopin.ingenMerknaderButtonText" />
          </strong>
        </div>
      </div>
    );
  }

  return (
    <div className="Page-gray">
      <div className="Page-section Page-section--narrow">
        <Button onClick={() => onClickWrapped(onClick, hasFeedback)} disabled={readonly}>
          <FormattedMessage id="slettMerknaderPopin.ingenMerknaderButtonText" />
        </Button>
      </div>
      <Popin
        id={popinId}
        title={intl.formatMessage({ id: "slettMerknaderPopin.title" })}
        closeableByExternalEvents
        saveButton={
          <>
            <Icon icon="trash" placement="before" />
            <FormattedMessage id="slettMerknaderPopin.jaSlett" />
          </>
        }
        onSave={() => confirmOnClick(onClick)}
        cancelButton={<FormattedMessage id="formLabels.avbryt" />}
      >
        <FormattedMessage id="slettMerknaderPopin.body" />
      </Popin>
    </div>
  );
};

IngenMerknader.propTypes = {
  onClick: PropTypes.func,
  kanHaIngenMerknader: PropTypes.bool,
  readonly: PropTypes.bool,
  hasFeedback: PropTypes.bool,
  harIngenMerknader: PropTypes.bool,
};

export default IngenMerknader;
