import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Select } from "@utdanningsdirektoratet/select";
import { malgruppeShape } from "utils/PropTypes";
import { useIntl } from "react-intl";
import { Element } from "react-scroll";
import FieldName from "../FieldName";

const fieldsToReset = [
  FieldName.REPRESENTERER,
  FieldName.STILLING,
  FieldName.FYLKE,
  FieldName.KOMMUNE,
  FieldName.UNDERTYPE,
  FieldName.BEKREFTREPRESENTASJON,
];

const Malgruppe = ({ fields, malgrupper, alleMalgrupper, resetFields }) => {
  const [localSektor, setLocalSektor] = useState("");
  const intl = useIntl();
  useEffect(() => {
    if (fields.sektor.value) setLocalSektor(fields.sektor.value);
  }, [fields.sektor.value]);

  const findMalgruppe = (malgruppe) => malgruppe[1].find((mg) => mg.sektor === localSektor) || malgruppe[1][0];

  const options = Object.entries(malgrupper)
    .sort((a, b) => findMalgruppe(b).rekkefolge - findMalgruppe(a).rekkefolge)
    .map((m) => ({
      value: findMalgruppe(m).id,
      text: m[0],
    }));

  const withDefault = [
    {
      value: "",
      text: `— ${intl.formatMessage({ id: "sendInnPopin.velg" })} —`,
    },
    ...options,
  ];

  const onChangeWrapped = (e) => {
    resetFields(fieldsToReset);
    fields.malgruppe.onChange(e);
    const valgtMalgruppe = alleMalgrupper.find((m) => m.id === parseInt(e.target.value, 10));
    if (valgtMalgruppe && malgrupper[valgtMalgruppe.tittel].length === 1) {
      fields.sektor.onChange(valgtMalgruppe.sektor);
      setLocalSektor(valgtMalgruppe.sektor);
    } else if (valgtMalgruppe) {
      fields.sektor.onChange("");
    }
  };

  return (
    <div className="SendInnPopin-flexInput">
      <Element name={FieldName.MALGRUPPE} />
      <Select
        {...fields.malgruppe}
        id="velgMalgruppe"
        className="SendInnPopin-select"
        onChange={onChangeWrapped}
        options={withDefault}
        aria-label={intl.formatMessage({ id: "sendInnPopin.velgMalgruppe" })}
      />
    </div>
  );
};

Malgruppe.propTypes = {
  fields: PropTypes.object.isRequired,
  malgrupper: PropTypes.object.isRequired,
  alleMalgrupper: PropTypes.arrayOf(malgruppeShape).isRequired,
  resetFields: PropTypes.func.isRequired,
};

export default Malgruppe;
