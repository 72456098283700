const FieldName = {
  NAVN: 'navn',
  EPOST: 'epost',
  SEKTOR: 'sektor',
  MALGRUPPE: 'malgruppe',
  STILLING: 'stilling',
  FYLKE: 'fylke',
  KOMMUNE: 'kommune',
  REPRESENTERER: 'representerer',
  UNDERTYPE: 'undertype',
  BEKREFTREPRESENTASJON: 'bekreftRepresentasjon'
};

export default FieldName;
