export const arrayUpdate = (array, index, newProperties) => {
  return [
    ...array.slice(0, index),
    { ...array[index], ...newProperties },
    ...array.slice(index + 1)
  ];
};

export const arrayRemove = (array, index) => {
  return [...array.slice(0, index), ...array.slice(index + 1)];
};
