import { useSelector } from "react-redux";
import { signaturShape } from "utils/PropTypes";
import { getTextWithLocale } from "../../../../utils/localeUtils";

const Signatur = ({ signatur }) => {
  const locale = useSelector((state) => state.locale);
  if (!signatur) return null;
  const { footer, hilsen, signaturNavn1, signaturNavn2, signaturStilling1, signaturStilling2 } = signatur;

  const malformHilsen = getTextWithLocale(hilsen, locale);
  const malformSignaturStilling1 = getTextWithLocale(signaturStilling1, locale);
  const malformSignaturStilling2 = getTextWithLocale(signaturStilling2, locale);
  const malformFooter = getTextWithLocale(footer, locale);

  return (
    <div className="Page-section u--marginBottom2">
      <div className="Grid-12-12 u--marginBottom2">{malformHilsen}</div>
      <div className="Grid-6-12">
        <div>{signaturNavn1}</div>
        <div>{malformSignaturStilling1}</div>
      </div>
      <div className="Grid-6-12">
        <div>{signaturNavn2}</div>
        <div>{malformSignaturStilling2}</div>
      </div>
      <div className="Grid-12-12 u--marginTop2">{malformFooter}</div>
    </div>
  );
};

Signatur.propTypes = {
  signatur: signaturShape,
};

export default Signatur;
