import PropTypes from "prop-types";
import { Checkbox } from "@utdanningsdirektoratet/checkbox";
import { Radio } from "@utdanningsdirektoratet/radio";
import { Tooltip } from "@utdanningsdirektoratet/tooltip";
import { malgruppeShape } from "utils/PropTypes";
import { Element } from "react-scroll";
import FieldName from "../FieldName";

const Undertype = ({ fields, valgtMalgruppe }) => {
  const onChangeWrapped = (e) => {
    const { value, checked } = e.target;

    let newValue = {};
    if (valgtMalgruppe.kanHaFlereUndertyper) {
      newValue = { ...fields.undertype.value };
    }
    newValue[value] = checked;
    fields.undertype.onChange(newValue);
  };

  const undertyper = valgtMalgruppe.undertyper
    .filter((undertype) => undertype.erAktiv)
    .map((undertype) => {
      if (valgtMalgruppe.kanHaFlereUndertyper) {
        return (
          <Checkbox
            name="undertyper"
            key={`undertype-${undertype.id}`}
            className="Grid-12-12 Grid-md-6-12 SendInnPopin-checkbox"
            label={undertype.tittel}
            checked={fields.undertype.value[undertype.id]}
            onChange={onChangeWrapped}
            value={undertype.id}
            error={fields.undertype.error}
          />
        );
      }
      return (
        <Radio
          name="undertyper"
          key={`undertype-${undertype.id}`}
          className="Grid-12-12 Grid-md-6-12 SendInnPopin-checkbox"
          label={undertype.tittel}
          onChange={onChangeWrapped}
          checked={fields.undertype.value[undertype.id]}
          value={undertype.id}
          error={fields.undertype.error}
        />
      );
    });

  if (undertyper.length === 0) return null;

  return (
    <div className="Grid-12-12 u--marginTop1">
      <Element name={FieldName.UNDERTYPE} />
      <Tooltip
        className="u--block"
        message={fields.undertype.message}
        align="right"
        position="top"
        disabled={!fields.undertype.error}
        alwaysShow
      >
        {undertyper}
      </Tooltip>
    </div>
  );
};

Undertype.propTypes = {
  fields: PropTypes.object.isRequired,
  valgtMalgruppe: malgruppeShape.isRequired,
};

export default Undertype;
