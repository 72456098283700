import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Linkify from "linkify-react";

const Fotnoter = ({ fotnoter, notatId }) => {
  if (fotnoter.length < 1) return null;

  return (
    <div className="Page-section Fotnoter">
      <div className="u-h2 Fotnoter-title">
        <FormattedMessage id="uttalelse.fotnoter" />
      </div>
      <ol className="Fotnoter-list">
        {fotnoter.map((fotnote) => {
          return (
            <li key={`fotnote-${notatId}-${fotnote.id}`} id={`fotnote-${notatId}-${fotnote.id}`}>
              <Linkify options={{ target: "_blank", attributes: { rel: "noopener noreferrer" } }}>{fotnote.text}</Linkify>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

Fotnoter.propTypes = {
  fotnoter: PropTypes.array,
  notatId: PropTypes.number,
};

export default Fotnoter;
