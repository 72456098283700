import * as React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "@utdanningsdirektoratet/checkbox";
import { Popin, show, close } from "@utdanningsdirektoratet/popin";
import { FormattedMessage } from "react-intl";

const popinId = "IngenMerknaderPopin";

const IngenMerknaderManueltRegistrert = ({ kanHaIngenMerknader, onClick, harIngenMerknader }) => {
  const [ingenMerknader, setIngenMerknader] = React.useState(false);

  const onClickWrapped = (_onClick, _ingenMerknader) => {
    setIngenMerknader(_ingenMerknader);
    if (_ingenMerknader) {
      show(popinId);
    } else {
      _onClick();
    }
  };

  const confirmOnClick = (_onClick) => {
    close(popinId);
    _onClick();
  };

  if (!kanHaIngenMerknader) return null;

  return (
    <div className="Page-gray">
      <div className="Page-section Page-section--narrow">
        <Checkbox
          label={<FormattedMessage id="slettMerknaderPopin.ingenMerknaderButtonText" />}
          onChange={(e) => onClickWrapped(onClick(e.target.checked), e.target.checked)}
          checked={harIngenMerknader}
        />
      </div>
      <Popin
        id={popinId}
        closeableByExternalEvents
        onSave={() => confirmOnClick(onClick(ingenMerknader))}
        saveButton={<FormattedMessage id="formLabels.ja" />}
        cancelButton={<FormattedMessage id="formLabels.avbryt" />}
      >
        <FormattedMessage id="slettMerknaderPopin.ingenMerknaderAdvarsel" />
      </Popin>
    </div>
  );
};

IngenMerknaderManueltRegistrert.propTypes = {
  onClick: PropTypes.func,
  kanHaIngenMerknader: PropTypes.bool,
  harIngenMerknader: PropTypes.bool,
};

export default IngenMerknaderManueltRegistrert;
