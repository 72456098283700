import PropTypes from 'prop-types';
import { GeografiObligatoriskhetEnum } from 'constants/serverEnums';
import { malgruppeShape } from 'utils/PropTypes';
import Kommune from './Kommune';
import Fylke from './Fylke';

const FylkeKommune = ({ fields, resetFields, valgtMalgruppe }) => {
  const visFylke = valgtMalgruppe.geografiObligatoriskhet === GeografiObligatoriskhetEnum.FylkeObligatorisk
    || valgtMalgruppe.geografiObligatoriskhet === GeografiObligatoriskhetEnum.FylkeOgKommuneObligatorisk;

  if (!visFylke) return null;

  return (
    <div className="Grid-12-12 SendInnPopin-wrapper">
      <Fylke fields={fields} resetFields={resetFields} />
      <Kommune fields={fields} valgtMalgruppe={valgtMalgruppe} />
    </div>
  );
};

FylkeKommune.propTypes = {
  fields: PropTypes.object.isRequired,
  valgtMalgruppe: malgruppeShape.isRequired,
  resetFields: PropTypes.func.isRequired
};

export default FylkeKommune;
