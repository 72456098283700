import { COMPLETE_AUTOSAVE } from './autoSaveDuck';

const defaultState = {};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case COMPLETE_AUTOSAVE:
      const { endret, eksternId } = action.payload;
      return { ...state, endret, eksternId };
    default:
      return state;
  }
};

export const getNotat = (notatId, hoering) => {
  return hoering.notat.find((n) => n.id === notatId) || hoering.notat[0];
};

export const getNextNotat = (notatId, hoering) => {
  const notat = getNotat(notatId, hoering);
  const index = hoering.notat.indexOf(notat);
  if (index >= 0 && index < hoering.notat.length - 1) {
    return hoering.notat[index + 1];
  }
  return notat;
};

export default reducer;
