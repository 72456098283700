import PropTypes from "prop-types";
import classnames from "classnames";
import { formatDate } from "@utdanningsdirektoratet/utils/date";
import { Icon } from "@utdanningsdirektoratet/icon";
import ReactRouterPropTypes from "react-router-prop-types";
import { TIMESTAMP_FORMAT } from "constants/dateFormats";
import { FormattedMessage } from "react-intl";
import { notatShape } from "utils/PropTypes";
import NotatLink from "./NotatLink";

const getNotatLink = (currentNotat, notat, location, ingenMerkander) => {
  const selected = currentNotat.id === notat.id;
  const itemClass = classnames({
    "Sidebar-item": true,
    "Sidebar-item--selected": selected,
  });
  return (
    <div className={itemClass} key={`sidebar-item-${notat.id}`}>
      <NotatLink location={location} notat={notat} ingenMerkander={ingenMerkander} />
      <Icon className="Sidebar-item-icon" type="small" icon="angle right" />
    </div>
  );
};

const Sidebar = ({ currentNotat, notater, location, endret, innsendt, showTimestamp }) => {
  const body = notater
    .filter((n) => !innsendt || n.uttalelse.hasFeedback || n.rekkefolge === 0)
    .map((notat) => getNotatLink(currentNotat, notat, location));

  if (body.length <= 1) return null;

  const notaterWithoutFeedback = notater
    .filter((n) => innsendt && !n.uttalelse.hasFeedback && n.rekkefolge !== 0)
    .map((notat) => getNotatLink(currentNotat, notat, location, true));

  const timestampClass = classnames({
    "Sidebar-timestamp": true,
    "Sidebar-timestamp--visible": showTimestamp && endret,
  });

  return (
    <nav className="Sidebar">
      <div className="Sidebar-wrapper">{body}</div>

      {innsendt && notaterWithoutFeedback.length > 0 ? (
        <div className="Sidebar-wrapper Sidebar-wrapper--faded">{notaterWithoutFeedback}</div>
      ) : null}

      <div className={timestampClass}>
        <FormattedMessage id="uttalelse.sistLagret" />: {endret ? formatDate(endret, TIMESTAMP_FORMAT) : null}
      </div>
    </nav>
  );
};

Sidebar.propTypes = {
  currentNotat: notatShape.isRequired,
  notater: PropTypes.arrayOf(notatShape).isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  endret: PropTypes.string,
  innsendt: PropTypes.bool,
  showTimestamp: PropTypes.bool,
};

export default Sidebar;
