import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { enums } from "logic/initialState";
import { malgruppeShape } from "utils/PropTypes";

import Sektor from "./Sektor";
import Malgruppe from "./Malgruppe";
import InnsenderInfo from "./InnsenderInfo";

const MalgruppeSektor = ({ valgtMalgruppe, malgrupper, fields, resetFields }) => {
  const intl = useIntl();
  const malgrupperForValgtSektor = malgrupper.filter((malgruppe) => !fields.sektor.value || malgruppe.sektor === fields.sektor.value);
  const malgrupperGroupByName = malgrupper.reduce((curr, val) => {
    if (fields.sektor.value === enums.sektor.enkeltperson && val.sektor === enums.sektor.enkeltperson) {
      if (!curr[val.tittel]) return { ...curr, [val.tittel]: [val] };
      return { ...curr, [val.tittel]: [...curr[val.tittel], val] };
    }
    if (fields.sektor.value !== enums.sektor.enkeltperson && val.sektor !== enums.sektor.enkeltperson) {
      if (!curr[val.tittel]) return { ...curr, [val.tittel]: [val] };
      return { ...curr, [val.tittel]: [...curr[val.tittel], val] };
    }
    return curr;
  }, {});
  const malgrupperForValgtMalgruppe = valgtMalgruppe ? malgrupperGroupByName[valgtMalgruppe.tittel] : [];

  if (malgrupperForValgtSektor.length === 0) return null;

  return (
    <div>
      {(malgrupperForValgtSektor.length > 1 || malgrupperForValgtMalgruppe.length > 1) && (
        <label className="SendInnPopin-label" htmlFor="velgMalgruppe">
          {intl.formatMessage({
            id: fields.sektor.value === enums.sektor.enkeltperson ? "sendInnPopin.jegErEn" : "sendInnPopin.jegRepresenterer",
          })}
        </label>
      )}
      <div className="Grid-12-12 SendInnPopin-wrapper">
        {Object.keys(malgrupperGroupByName).length > 1 ? (
          <Malgruppe fields={fields} malgrupper={malgrupperGroupByName} alleMalgrupper={malgrupper} resetFields={resetFields} />
        ) : null}
        {fields.sektor.value !== enums.sektor.enkeltperson ? (
          <Sektor malgrupper={malgrupperForValgtMalgruppe} fields={fields} resetFields={resetFields} />
        ) : null}
      </div>
      {fields.sektor.value && fields.malgruppe.value ? (
        <InnsenderInfo fields={fields} malgrupper={malgrupper} resetFields={resetFields} />
      ) : null}
    </div>
  );
};

MalgruppeSektor.propTypes = {
  valgtMalgruppe: malgruppeShape,
  malgrupper: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  resetFields: PropTypes.func.isRequired,
};

export default MalgruppeSektor;
