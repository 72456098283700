import PropTypes from "prop-types";
import { Select } from "@utdanningsdirektoratet/select";
import { useIntl } from "react-intl";
import { fylker } from "logic/initialState";
import { Element } from "react-scroll";
import FieldName from "../FieldName";

const fieldsToReset = [FieldName.KOMMUNE];

const Fylke = ({ fields, resetFields }) => {
  const intl = useIntl();
  const onChangeWrapped = (e) => {
    resetFields(fieldsToReset);
    fields.fylke.onChange(e);
  };

  const gjeldendeFylker = fylker
    .filter((f) => f.aktiv)
    .map((fylke) => (
      <option key={fylke.fylkesNr} value={fylke.fylkesNr}>
        {fylke.navn}
      </option>
    ));
  const gamleFylker = fylker
    .filter((f) => !f.aktiv)
    .map((fylke) => (
      <option key={fylke.fylkesNr} value={fylke.fylkesNr}>
        {fylke.navn}
      </option>
    ));

  return (
    <div className="SendInnPopin-flexInput">
      <Element name={FieldName.FYLKE} />
      <Select
        {...fields.fylke}
        className="SendInnPopin-select"
        onChange={onChangeWrapped}
        aria-label={intl.formatMessage({ id: "sendInnPopin.velgFylke" })}
      >
        <option value="">{`— ${intl.formatMessage({ id: "sendInnPopin.velgFylke" })} —`}</option>
        {gamleFylker.length > 0 && gjeldendeFylker.length > 0 ? (
          <optgroup label={intl.formatMessage({ id: "sendInnPopin.gjeldendeFylker" })}>{gjeldendeFylker}</optgroup>
        ) : (
          gjeldendeFylker
        )}
        {gamleFylker.length > 0 && gjeldendeFylker.length > 0 ? (
          <optgroup label={intl.formatMessage({ id: "sendInnPopin.gamleFylker" })}>{gamleFylker}</optgroup>
        ) : (
          gamleFylker
        )}
      </Select>
    </div>
  );
};

Fylke.propTypes = {
  fields: PropTypes.object.isRequired,
  resetFields: PropTypes.func.isRequired,
};

export default Fylke;
