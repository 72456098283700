import { useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@utdanningsdirektoratet/icon";
import { FormattedMessage } from "react-intl";
import { formatDate } from "@utdanningsdirektoratet/utils/date";
import { DATE_FORMAT } from "constants/dateFormats";
import emailClosed from "images/email-closed.svg";
import emailOpen from "images/email-open.svg";
import emailText from "images/email-text.svg";
import hand from "images/hand.svg";
import { show } from "@utdanningsdirektoratet/popin";
import { redigerEpostPopinId } from "constants/popinIds";
import { Button } from "@utdanningsdirektoratet/button";
import RedigerEpostPopin from "./RedigerEpostPopin";

const TutorialOverlay = ({ eksternId, isOpen, epost, innsendtDato }) => {
  const [overlayIsOpen, setOverlayIsOpen] = useState(isOpen);

  const onConfirmLaterClick = (e) => {
    e.preventDefault();

    setOverlayIsOpen(false);
  };

  const onEditEmailClick = (e) => {
    e.preventDefault();
    show(redigerEpostPopinId);
  };

  if (!overlayIsOpen) return null;

  return (
    <div className="TutorialOverlay">
      <div className="TutorialOverlay-wrapper">
        <div className="TutorialOverlay-box">
          <img src={emailClosed} alt="E-post" />
          <div className="TutorialOverlay-badge">1</div>
          <div>
            <FormattedMessage id="innsendtUttalelse.bekreftelsesEpostSendtTil" />
          </div>
          <div data-hj-suppress>
            <strong>{epost}</strong>
          </div>
          <div>{formatDate(innsendtDato, DATE_FORMAT)}</div>
        </div>
        <div className="TutorialOverlay-box TutorialOverlay-box--icon">
          <Icon className="TutorialOverlay-arrow" icon="arrow right" type="large" />
        </div>
        <div className="TutorialOverlay-box">
          <img src={emailOpen} alt="E-post" />
          <div>
            <FormattedMessage id="innsendtUttalelse.vennligstApneEpostFra" />{" "}
            <FormattedMessage id="innsendtUttalelse.innsendtTilInstitusjon" />.
          </div>
          <p className="u--marginTop1 u--marginBottom1">
            <FormattedMessage id="innsendtUttalelse.ikkeMottattEpost" />
          </p>
          <Button className="TutorialOverlay-button" onClick={(e) => onEditEmailClick(e)}>
            <FormattedMessage id="innsendtUttalelse.sendEpostPåNytt" />
          </Button>
        </div>
        <div className="TutorialOverlay-box TutorialOverlay-box--icon">
          <Icon className="TutorialOverlay-arrow" icon="arrow right" type="large" />
        </div>
        <div className="TutorialOverlay-box">
          <img src={emailText} alt="" />
          <img src={hand} className="TutorialOverlay-hand" alt="" />
          <div>
            <FormattedMessage id="innsendtUttalelse.klikkPaLenken" />
          </div>
        </div>
      </div>
      <div className="TutorialOverlay-footer">
        <br />
        <br />
        <a // eslint-disable-line
          href="#"
          onClick={(e) => onConfirmLaterClick(e)}
        >
          <FormattedMessage id="innsendtUttalelse.bekreftSenere" />
        </a>
      </div>
      <RedigerEpostPopin id={redigerEpostPopinId} eksternId={eksternId} epost={epost} />
    </div>
  );
};

TutorialOverlay.propTypes = {
  eksternId: PropTypes.string,
  isOpen: PropTypes.bool,
  epost: PropTypes.string,
  innsendtDato: PropTypes.string,
};

export default TutorialOverlay;
