import PropTypes from "prop-types";
import { Icon } from "@utdanningsdirektoratet/icon";
import { Button } from "@utdanningsdirektoratet/button";
import { Tooltip } from "@utdanningsdirektoratet/tooltip";
import { JaNeiSvarEnum } from "constants/serverEnums";
import { validationErrorsShape } from "utils/PropTypes";
import classnames from "classnames";
import { useIntl, FormattedMessage } from "react-intl";
import Obligatorisk from "./Obligatorisk";

const JaNeiSvar = ({ readonly, obligatorisk, forhandsvisning, sporsmalId, updateSporsmalUttalelse, jaNeiSvar, validationErrors }) => {
  const intl = useIntl();
  if (!forhandsvisning && readonly) {
    let svar = jaNeiSvar.value;
    if (svar === JaNeiSvarEnum.IkkeAngitt) return null;
    if (svar === JaNeiSvarEnum.VetIkke) svar = <FormattedMessage id="formLabels.vetIkke" />;
    if (svar === JaNeiSvarEnum.Ja) {
      svar = (
        <span>
          <Icon icon="check" type="small" placement="before" />
          <FormattedMessage id="formLabels.ja" />
        </span>
      );
    }
    if (svar === JaNeiSvarEnum.Nei) {
      svar = (
        <span>
          <Icon icon="cross" type="small" placement="before" />
          <FormattedMessage id="formLabels.nei" />
        </span>
      );
    }
    return <div className="Sporsmal-body Sporsmal-body--bold">{svar}</div>;
  }
  const error = validationErrors.length > 0;
  const buttonClass = classnames({
    "Sporsmal-button": true,
    "Sporsmal-button--error": error,
  });
  const button = (val, icon, txt) => {
    const iconElement = icon ? <Icon icon={icon} type="small" placement="before" /> : null;
    const active = jaNeiSvar.value === val;
    return (
      <Button
        className={buttonClass}
        active={active}
        disabled={readonly}
        onClick={() =>
          updateSporsmalUttalelse(sporsmalId, {
            jaNeiSvar: { value: active ? JaNeiSvarEnum.IkkeAngitt : val },
          })
        }
      >
        {iconElement}
        {txt}
      </Button>
    );
  };

  const validationErrorMessagesMalform = validationErrors.map((err) => intl.formatMessage(err.text)).join("\n");

  return (
    <div className="Sporsmal-body">
      <Tooltip className="u--block" message={validationErrorMessagesMalform} position="top" align="left" disabled={!error} alwaysShow>
        <Obligatorisk isObligatorisk={obligatorisk}>
          {button(JaNeiSvarEnum.Ja, "check", <FormattedMessage id="formLabels.ja" />)}
          {button(JaNeiSvarEnum.Nei, "cross", <FormattedMessage id="formLabels.nei" />)}
          {button(JaNeiSvarEnum.VetIkke, null, <FormattedMessage id="formLabels.vetIkke" />)}
        </Obligatorisk>
      </Tooltip>
    </div>
  );
};

JaNeiSvar.propTypes = {
  sporsmalId: PropTypes.number.isRequired,
  updateSporsmalUttalelse: PropTypes.func.isRequired,
  jaNeiSvar: PropTypes.shape({
    value: PropTypes.oneOf([JaNeiSvarEnum.Ja, JaNeiSvarEnum.Nei, JaNeiSvarEnum.VetIkke, JaNeiSvarEnum.IkkeAngitt]).isRequired,
  }).isRequired,
  readonly: PropTypes.bool.isRequired,
  obligatorisk: PropTypes.bool,
  forhandsvisning: PropTypes.bool.isRequired,
  validationErrors: validationErrorsShape.isRequired,
};

export default JaNeiSvar;
