import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import { notatShape } from 'utils/PropTypes';
import { connect } from 'react-redux';
import { getNotatUttalelse } from 'ducks/uttalelseDuck';
import { getValidation, filterByType } from 'ducks/validationDuck.js';
import { svarTypes } from 'constants/svarTypes';
import { scrollToId, ScrollType } from 'utils/scrollUtils';

import PositivNegativSvar from './components/PositivNegativ';
import Kommentar from './components/Kommentar';

const NotatUttalelse = (props) => {
  const { forhandsvisning, validation, notat, uttalelse, ingenMerknader, updateNotatUttalelse, editManueltRegistert } = props;
  const { kanHaGenerellHoldning, kanHaGenerellKommentar, id } = notat;

  if (!kanHaGenerellHoldning && !kanHaGenerellKommentar) return null;

  const readonly = props.readonly && (!editManueltRegistert || ingenMerknader);

  return (
    <div key={`notatuttalelse-${id}`} className="Notatuttalelse">
      <div className="Sporsmal" id={`notat_${id}`}>
        <Element name={scrollToId(ScrollType.NOTAT, id)} />
        <div>
          {kanHaGenerellHoldning
            ? (
              <PositivNegativSvar
                id={id}
                updateUttalelse={updateNotatUttalelse}
                positivNegativSvar={uttalelse.positivNegativSvar}
                readonly={readonly}
                forhandsvisning={forhandsvisning}
                validationErrors={filterByType(svarTypes.POSITIV_NEGATIV, validation)}
                generell
              />
            )
            : null}

          {kanHaGenerellKommentar
            ? (
              <Kommentar
                readonly={readonly}
                forhandsvisning={forhandsvisning}
                kommentar={uttalelse.kommentar}
                id={id}
                updateUttalelse={updateNotatUttalelse}
                validationErrors={filterByType(svarTypes.KOMMENTAR, validation)}
                generell
              />
            )
            : null}
        </div>
      </div>
    </div>
  );
};

NotatUttalelse.propTypes = {
  notat: notatShape.isRequired,
  updateNotatUttalelse: PropTypes.func.isRequired,
  uttalelse: PropTypes.object.isRequired,
  validation: PropTypes.array.isRequired,
  readonly: PropTypes.bool.isRequired,
  ingenMerknader: PropTypes.bool.isRequired,
  forhandsvisning: PropTypes.bool.isRequired,
  editManueltRegistert: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
  const notatId = ownProps.notat.id;
  return {
    ingenMerknader: state.uttalelse.ingenMerknader,
    uttalelse: getNotatUttalelse(notatId, state.uttalelse),
    validation: getValidation(notatId, state.validation.notater)
  };
};

export default connect(mapStateToProps)(NotatUttalelse);
