import PropTypes from 'prop-types';
import { malgruppeShape } from 'utils/PropTypes';
import Undertype from './Undertype';
import Representerer from './Representerer';
import MinStilling from './MinStilling';
import FylkeKommune from './FylkeKommune';

const InnsenderInfo = ({ fields, resetFields, malgrupper }) => {
  const malgruppe = fields.malgruppe.value;

  if (!malgruppe) return null;

  const valgtMalgruppe = malgrupper.find((m) => m.id === parseInt(malgruppe, 10));

  return (
    <div>
      <Representerer fields={fields} valgtMalgruppe={valgtMalgruppe} />
      <FylkeKommune fields={fields} valgtMalgruppe={valgtMalgruppe} resetFields={resetFields} />
      <Undertype fields={fields} valgtMalgruppe={valgtMalgruppe} />
      <MinStilling fields={fields} valgtMalgruppe={valgtMalgruppe} />
    </div>
  );
};

InnsenderInfo.propTypes = {
  fields: PropTypes.object.isRequired,
  malgrupper: PropTypes.arrayOf(malgruppeShape).isRequired,
  resetFields: PropTypes.func.isRequired
};

export default InnsenderInfo;
