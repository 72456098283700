const readQueryParam = (search, value) => new URLSearchParams(search).get(value);

export const CHANGE_LOCALE = 'locale/CHANGE_LOCALE';

const reducer = (state = readQueryParam(window.location.search, "maalform"), action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      state = action.payload
      return state
    default:
      return state;
  }
};

export const changeLocale = (locale) => {
  return {
    type: CHANGE_LOCALE,
    payload: locale
  };
};


export default reducer;
