import PropTypes from "prop-types";
import { Select } from "@utdanningsdirektoratet/select";
import { GeografiObligatoriskhetEnum } from "constants/serverEnums";
import { malgruppeShape } from "utils/PropTypes";
import { useIntl } from "react-intl";
import { fylker } from "logic/initialState";
import { Element } from "react-scroll";
import FieldName from "../FieldName";

const selectHasValue = (value) => value && value !== "null";

const Kommune = ({ fields, valgtMalgruppe }) => {
  const visKommune =
    valgtMalgruppe.geografiObligatoriskhet === GeografiObligatoriskhetEnum.FylkeOgKommuneObligatorisk && selectHasValue(fields.fylke.value);

  const intl = useIntl();
  if (!visKommune) return null;

  const valgtFylke = fylker.find((fylke) => fylke.fylkesNr === fields.fylke.value);

  const gjeldendeKommuner = valgtFylke.kommuner
    .filter((f) => f.aktiv)
    .map((kommune) => (
      <option key={kommune.kommuneNr} value={kommune.kommuneNr}>
        {kommune.navn}
      </option>
    ));
  const gamleKommuner = valgtFylke.kommuner
    .filter((f) => !f.aktiv)
    .map((kommune) => (
      <option key={kommune.kommuneNr} value={kommune.kommuneNr}>
        {kommune.navn}
      </option>
    ));

  return (
    <div className="SendInnPopin-flexInput">
      <Element name={FieldName.KOMMUNE} />
      <Select className="SendInnPopin-select" {...fields.kommune} aria-label={intl.formatMessage({ id: "sendInnPopin.velgKommune" })}>
        <option value="">{`— ${intl.formatMessage({ id: "sendInnPopin.velgKommune" })} —`}</option>
        {gamleKommuner.length > 0 && gjeldendeKommuner.length > 0 ? (
          <optgroup label={intl.formatMessage({ id: "sendInnPopin.gjeldendeKommuner" })}>{gjeldendeKommuner}</optgroup>
        ) : (
          gjeldendeKommuner
        )}
        {gamleKommuner.length > 0 && gjeldendeKommuner.length > 0 ? (
          <optgroup label={intl.formatMessage({ id: "sendInnPopin.gamleKommuner" })}>{gamleKommuner}</optgroup>
        ) : (
          gamleKommuner
        )}
      </Select>
    </div>
  );
};

Kommune.propTypes = {
  fields: PropTypes.object.isRequired,
  valgtMalgruppe: malgruppeShape.isRequired,
};

export default Kommune;
