import debounce from "lodash.debounce";
import { postApi, putApi } from "api/fetchWrapper";
import mapSporsmalUttalelserVm from "vm/mapSporsmalUttalelserVm";
import mapSeksjonUttalelserVm from "vm/mapSeksjonUttalelserVm";
import mapNotatUttalelserVm from "vm/mapNotatUttalelserVm";
import { addOrUpdateUtkast } from "utils/localStorageUtils";
import { navigateToUtkast } from "./navigationDuck";
import { HoeringClient } from "../ApiClients";

// Hack
export const UPDATE_SPORSMAL_UTTALELSE_INIT = "uttalelse/UPDATE_SPORSMAL_UTTALELSE_INIT";

const timeout = 2000;

// states
export const STATUS_INACTIVE = "inactive";
export const STATUS_PENDING = "pending";
export const STATUS_COMPLETE = "complete";
export const STATUS_FAILED = "failed";

// actions
const START_AUTOSAVE = "autoSave/START_AUTOSAVE";
export const COMPLETE_AUTOSAVE = "autoSave/COMPLETE_AUTOSAVE";
export const FAILED_AUTOSAVE = "autoSave/FAILED_AUTOSAVE";
const RESET_AUTOSAVE = "autoSave/RESET_AUTOSAVE";
const IMMEDIATE_AUTOSAVE = "autoSave/IMMEDIATE_AUTOSAVE";

const client = new HoeringClient();

// reducers
const reducer = (state = STATUS_INACTIVE, action) => {
  switch (action.type) {
    case START_AUTOSAVE:
      return STATUS_PENDING;
    case COMPLETE_AUTOSAVE:
      window.onbeforeunload = () => {};
      return STATUS_COMPLETE;
    case RESET_AUTOSAVE:
      window.onbeforeunload = () => {};
      return STATUS_INACTIVE;
    case FAILED_AUTOSAVE:
      window.onbeforeunload = () => {};
      return STATUS_FAILED;
    default:
      return state;
  }
};

// side effects
const resetComplete = ({ getState, dispatch }) => {
  const status = getState().autoSave;
  if (status === STATUS_COMPLETE || status === STATUS_FAILED) {
    dispatch({ type: RESET_AUTOSAVE });
  }
};

const pendingImmediates = [];

const flushImmediates = (autoSaveFunc) => {
  const pending = pendingImmediates.splice(0, pendingImmediates.length);
  if (pending.length === 0) return false;
  return autoSaveFunc().then(
    (r) => pending.forEach((p) => p.onSuccess(r)),
    (e) => pending.forEach((p) => p.onFailure(e))
  );
};

const triggerAutoSave = ({ getState, dispatch }) => {
  const state = getState();

  dispatch({ type: START_AUTOSAVE });
  const sporsmalUttalelser = mapSporsmalUttalelserVm(state.uttalelse.sporsmal, state.initialUttalelse.sporsmal);
  const seksjonUttalelser = mapSeksjonUttalelserVm(state.uttalelse.seksjoner);
  const notatUttalelser = mapNotatUttalelserVm(state.uttalelse.notater, state.initialUttalelse.notater);
  const externalId = state.hoering.eksternId;
  const unsaved = externalId === "00000000-0000-0000-0000-000000000000";

  return unsaved
    ? postApi(
        client.autoSavePost(state.hoering.hoeringId, {
          externalId,
          sporsmalUttalelser,
          seksjonUttalelser,
          notatUttalelser,
          userMålform: state.locale,
        }),
        {
          showProgress: true,
          showSlowAction: false,
        }
      ).then(({ endret, hoeringId, eksternId }) => {
        addOrUpdateUtkast(hoeringId, { eksternId, endret });
        flushImmediates(() => triggerAutoSave({ getState, dispatch }));
        navigateToUtkast(eksternId, true)(dispatch, getState);
        return dispatch({
          type: COMPLETE_AUTOSAVE,
          payload: { endret, eksternId },
        });
      })
    : putApi(
        client.autoSavePut(state.hoering.hoeringId, {
          sporsmalUttalelser,
          seksjonUttalelser,
          notatUttalelser,
          eksternId: externalId,
          userMålform: state.locale,
        }),
        {
          showProgress: true,
          showSlowAction: false,
        }
      ).then(({ endret, hoeringId, eksternId }) => {
        addOrUpdateUtkast(hoeringId, { eksternId, endret });
        flushImmediates(() => triggerAutoSave({ getState, dispatch }));
        return dispatch({
          type: COMPLETE_AUTOSAVE,
          payload: { endret, eksternId },
        });
      });
};

const triggerAutoSaveWithDelay = debounce(({ getState, dispatch }) => {
  const status = getState().autoSave;
  if (status === STATUS_PENDING) {
    return triggerAutoSaveWithDelay({ getState, dispatch });
  }
  return triggerAutoSave({ getState, dispatch }).catch(() => dispatch({ type: FAILED_AUTOSAVE }));
}, timeout);

const triggerAutoSaveWithoutDelay = ({ getState, dispatch, onSuccess, onFailure }) => {
  triggerAutoSaveWithDelay.cancel(); // cancel debounce so autosave won't trigger again after one without delay
  const status = getState().autoSave;
  return status === STATUS_PENDING
    ? pendingImmediates.push({ onSuccess, onFailure })
    : triggerAutoSave({ getState, dispatch }).then(onSuccess, onFailure);
};

export const saveNow = () => (dispatch) => {
  const payload = {};
  const promise = new Promise((resolve, reject) => {
    payload.onSuccess = resolve;
    payload.onFailure = reject;
  });
  dispatch({ type: IMMEDIATE_AUTOSAVE, payload });
  return promise;
};

const actions = [];
export const registerActionForAutoSave = (action) => actions.push(action);

export const autoSaveMiddleWare =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    const state = getState();
    if (state.hoering.manueltRegistrert && state.hoering.manuellRegistreringsKode) {
      return next(action);
    }

    // Hack galore, dirty AF
    // Don't autosave on orgnr updated from url query
    if (action.type === UPDATE_SPORSMAL_UTTALELSE_INIT) {
      next(action);
    }

    if (action.type === IMMEDIATE_AUTOSAVE) {
      const { onSuccess, onFailure } = action.payload;
      triggerAutoSaveWithoutDelay({ getState, dispatch, onSuccess, onFailure });
      return false;
    }
    if (actions.some((a) => a === action.type)) {
      window.onbeforeunload = () => false;
      resetComplete({ getState, dispatch });
      triggerAutoSaveWithDelay({ getState, dispatch });
    }
    return next(action);
  };

export default reducer;
