export const svarTypes = {
  POSITIV_NEGATIV: 'positivNegativ',
  JA_NEI: 'jaNei',
  ENIG_UENIG: 'enigUenig',
  ETT_VALG: 'ettValg',
  FLERE_VALG: 'flereValg',
  HELTALL: 'heltall',
  KORTTEKST: 'korttekst',
  DATO: 'dato',
  KOMMENTAR: 'kommentar',
  VEDLEGG: 'vedlegg',
  FYLKEKOMMUNE: 'fylkeKommune'
};
