/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-target-blank */
import { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Footer } from "@utdanningsdirektoratet/layout";
import {
  CookieBanner,
  MatomoCookieVendor,
  NecessaryCookie,
  SentryCookieVendor,
  StatisticsCookie,
} from "@utdanningsdirektoratet/cookiebanner";
import { FormattedMessage, useIntl } from "react-intl";
import { personvernerklaering, footer } from "logic/initialState";
import { onKeyDown } from "@utdanningsdirektoratet/utils/uu";

const HoeringsFooter = ({ hoeringId }) => {
  const intl = useIntl();
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  const locale = useSelector((state) => state.locale);
  const search = new URLSearchParams(window.location.search);
  search.set("maalform", locale);
  if (hoeringId) search.set("hoeringId", hoeringId);

  const cookiesLink = (
    <a
      href={personvernerklaering?.url ?? `/Cookies?${search.toString()}`}
      target={personvernerklaering?.blank ? "_blank" : null}
      rel={personvernerklaering?.blank ? "noopener noreferrer" : null}
    >
      <FormattedMessage id="footer.personvernerklaering" />
    </a>
  );

  const links = [
    ...(footer ?? []).map((f) => (
      <a
        href={f.url}
        target={f.blank ? "_blank" : null}
        rel={f.blank ? "noopener noreferrer" : null}
      >
        {f.tekst}
      </a>
    )),
    cookiesLink,
    <div
      tabIndex={0}
      role="link"
      onClick={() => setShowCookieBanner(true)}
      onKeyDown={onKeyDown(() => setShowCookieBanner(true))}
    >
      <FormattedMessage id="footer.cookiesInnstillinger" />
    </div>,
  ];

  return (
    <div className="Footer">
      <Footer items={links} />
      <CookieBanner
        necessaryCookie={NecessaryCookie([SentryCookieVendor])}
        title={intl.formatMessage({ id: "cookies.cookiesSamtykke" })}
        cookies={[
          {
            ...StatisticsCookie([
              ...[window.matomoId ? MatomoCookieVendor : null].filter(Boolean),
            ]),
            description: StatisticsCookie().description?.replace(
              "Utdanningsdirektoratet ",
              "",
            ),
          },
        ]}
        show={showCookieBanner}
        onAccept={() => setShowCookieBanner(false)}
      />
    </div>
  );
};

HoeringsFooter.propTypes = {
  hoeringId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default HoeringsFooter;
