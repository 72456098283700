import { LOCATION_CHANGE, push, replace } from 'connected-react-router';
import { scrollToId, ScrollType } from 'utils/scrollUtils';

const readQueryParam = (search, value) => new URLSearchParams(search).get(value);

const reducer = (state = { notatId: readQueryParam(window.location.search, 'notatId'), location: window.location }, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      const { search } = action.payload.location;
      const notatId = readQueryParam(search, 'notatId');
      const scrollTo = readQueryParam(search, 'scrollTo');
      const utkast = readQueryParam(search, 'utkast');
      const scrollToTop = state.notatId === notatId && !(utkast && !state.utkast) && !action.payload.hash;
      return { ...state, search, notatId, utkast, scrollTo, scrollToTop, location: action.payload.location };
    default:
      return { ...state, scrollToTop: false };
  }
};

export const navigateToNotat = (notatId, scrollTo) => (dispatch, getState) => {
  const search = new URLSearchParams(getState().navigation.location.search);
  search.set('notatId', notatId);
  if (scrollTo === true) {
    search.set('scrollTo', scrollToId(ScrollType.NOTAT, notatId));
  } else if (scrollTo) {
    search.set('scrollTo', scrollTo);
  } else {
    search.delete('scrollTo');
  }
  search.delete('reset');
  dispatch(push(`?${search.toString()}`));
};

export const navigateToSeksjon = (seksjonId) => (dispatch, getState) => {
  const notat = getState().hoering.notat.find((n) => n.seksjons.some((s) => s.id === seksjonId));
  navigateToNotat(notat.id, scrollToId(ScrollType.SEKSJON, seksjonId))(dispatch, getState);
};

export const navigateToSporsmal = (sporsmalId) => (dispatch, getState) => {
  const notat = getState().hoering.notat.find((n) => n.seksjons.some((s) => s.sporsmals.some((spm) => spm.id === sporsmalId)));
  navigateToNotat(notat.id, scrollToId(ScrollType.SPORSMAL, sporsmalId))(dispatch, getState);
};

export const navigateToFootnote = (footNoteId, notatId) => () => {
  window.location.hash = `#fotnote-${notatId}-${footNoteId}`;
};

export const navigateToUtkast = (eksternId, withReplace = false) => (dispatch, getState) => {
  if (withReplace) {
    const search = new URLSearchParams(getState().navigation.search);
    search.set('utkast', eksternId);
    dispatch(replace(`?${search.toString()}`));
  } else {
    const search = new URLSearchParams(getState().navigation.search);
    search.set('utkast', eksternId);
    dispatch(push(`?${search.toString()}`));
    window.location.reload();
  }
};

export const removeScrollTo = () => (dispatch, getState) => {
  const search = new URLSearchParams(getState().navigation.search);
  search.delete('scrollTo');
  dispatch(replace(`?${search.toString()}`));
};

export const removeReset = () => (dispatch, getState) => {
  const search = new URLSearchParams(getState().navigation.search);
  search.delete('reset');
  dispatch(replace(`?${search.toString()}`));
};

export const removeInnsendingsKode = () => (dispatch, getState) => {
  const search = new URLSearchParams(getState().navigation.search);
  search.delete('innsendingsKode');
  dispatch(replace(`?${search.toString()}`));
};

export default reducer;
