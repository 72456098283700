import PropTypes from "prop-types";
import { Input } from "@utdanningsdirektoratet/input";
import { ObligatoriskhetEnum } from "constants/serverEnums";
import { malgruppeShape } from "utils/PropTypes";
import { useIntl } from "react-intl";
import { Element } from "react-scroll";
import FieldName from "../FieldName";

const MinStilling = ({ fields, valgtMalgruppe }) => {
  const intl = useIntl();
  const visMinStilling =
    valgtMalgruppe.innsenderStillingObligatoriskhet === ObligatoriskhetEnum.Obligatorisk ||
    valgtMalgruppe.innsenderStillingObligatoriskhet === ObligatoriskhetEnum.Valgfritt;

  if (!visMinStilling) return null;

  const stillingLabel =
    valgtMalgruppe.innsenderStillingObligatoriskhet !== ObligatoriskhetEnum.Obligatorisk
      ? `${intl.formatMessage({ id: "sendInnPopin.valgfritt" })}`
      : null;

  return (
    <div className="Grid-12-12">
      <Element name={FieldName.STILLING} />
      <label className="SendInnPopin-label" htmlFor="minStilling">
        {intl.formatMessage({ id: "sendInnPopin.minStilling" })}
      </label>
      <Input
        {...fields.stilling}
        id="minStilling"
        className="SendInnPopin-input"
        placeholder={stillingLabel}
        aria-label={intl.formatMessage({ id: "sendInnPopin.minStilling" })}
      />
    </div>
  );
};

MinStilling.propTypes = {
  fields: PropTypes.object.isRequired,
  valgtMalgruppe: malgruppeShape.isRequired,
};

export default MinStilling;
