import { isSporsmalBesvart } from "logic/validation";
import { getKommentar } from "./vmUtils";

export default (sporsmalUttalelser, initial) =>
  Object.keys(sporsmalUttalelser)
    .map((id) => ({ id, utt: sporsmalUttalelser[id], initialUtt: initial[id].utt }))
    .filter((u) => isSporsmalBesvart(u.utt) || !isSporsmalBesvart(u.initialUtt))
    .map((u) => ({
      id: u.id,
      positivNegativ: u.utt.positivNegativSvar ? u.utt.positivNegativSvar.value : undefined,
      jaNei: u.utt.jaNeiSvar ? u.utt.jaNeiSvar.value : undefined,
      enigUenig: u.utt.enigUenigSvar ? u.utt.enigUenigSvar.value : undefined,
      kommentar: getKommentar(u.utt, u.initialUtt),
      kortTekst: u.utt.kortTekstSvar && u.utt.kortTekstSvar.value ? u.utt.kortTekstSvar.value : undefined,
      heltall: u.utt.heltallSvar && (u.utt.heltallSvar.value || u.utt.heltallSvar.value === 0) ? u.utt.heltallSvar.value : undefined,
      dato: u.utt.datoSvar && u.utt.datoSvar.value ? u.utt.datoSvar.value : undefined,
      fylkeKommune: u.utt.fylkeKommuneSvar && u.utt.fylkeKommuneSvar.value ? u.utt.fylkeKommuneSvar.value : undefined,
      ettValgId: u.utt.ettValgId ? u.utt.ettValgId.value : undefined,
      flereValgIds: u.utt.flereValgIds,
      egendefinertSvar: u.utt.egendefinertSvar
        ? Object.keys(u.utt.egendefinertSvar).map((svarAlternativId) => ({
            svarAlternativId,
            egendefinertSvar: u.utt.egendefinertSvar[svarAlternativId],
          }))
        : undefined,
    }));
