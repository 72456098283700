import mapSporsmalUttalelserVm from './mapSporsmalUttalelserVm';
import mapSeksjonUttalelserVm from './mapSeksjonUttalelserVm';
import mapNotatUttalelserVm from './mapNotatUttalelserVm';

export default (eksternId, uttalelse, initialUttalelse, hoering, userMalform, values) => {
  const sporsmalUttalelserVm = mapSporsmalUttalelserVm(uttalelse.sporsmal, initialUttalelse.sporsmal);
  const seksjonUttalelserVm = mapSeksjonUttalelserVm(uttalelse.seksjoner);
  const notatUttalelserVm = mapNotatUttalelserVm(uttalelse.notater, initialUttalelse.notater);

  const vm = {
    eksternId,
    innsendingsKode: hoering.innsendingsKode,
    ingenMerknader: uttalelse.ingenMerknader,
    sporsmalUttalelser: sporsmalUttalelserVm,
    seksjonUttalelser: seksjonUttalelserVm,
    notatUttalelser: notatUttalelserVm,
    userMålform: userMalform
  };

  if (values) {
    return {
      ...vm,
      innsender: {
        navn: values.navn,
        epost: values.epost,
        malgruppe: values.malgruppe,
        representerer: values.representerer,
        stilling: values.stilling,
        fylke: values.fylke,
        kommune: values.kommune,
        userMålform: values.userMålform,
        undertype: Object.keys(values.undertype).filter(key => values.undertype[key])
      }
    };
  }

  return vm;
};
