import Progress from "react-progress-2";
import { show, hide } from "@utdanningsdirektoratet/slowaction";

const hideOverlays = (showProgress, showSlowAction) => {
  if (showProgress) {
    Progress.hide();
  }
  if (showSlowAction) {
    hide();
  }
};

const showOverlays = (showProgress, showSlowAction, slowActionText) => {
  if (showSlowAction) {
    show(slowActionText);
  }
  if (showProgress) {
    Progress.show();
  }
};


const handleError = async (error) => {
  let errorBody;
  if (typeof error.response === "string") errorBody = JSON.parse(error.response);
  else errorBody = await error.response?.json();
  const err = new Error();
  if (errorBody?.errorType === "FormError") {
    err.title = "Feil ved innsending";
    err.message = Object.keys(errorBody.errors)
      .map((key) => errorBody.errors[key])
      .join("\n");
  } else if (errorBody) {
    err.title = errorBody.title;
    err.message = errorBody.message;
  } else {
    err.title = "En ukjent feil oppsto";
    err.message = "Kontakt systemansvarlig";
  }

  return err;
};

const fetchWrapper = async (promise, options) => {
  const { slowActionText, showSlowAction = false, showProgress = false } = options;

  try {
    showOverlays(showProgress, showSlowAction, slowActionText);
    return await promise;
  } catch (e) {
    throw await handleError(e);
  } finally {
    hideOverlays(showProgress, showSlowAction);
  }
};

export const getApi = async (promise, options) => fetchWrapper(promise, { showSlowAction: false, showProgress: true, ...options });
export const postApi = async (promise, options) => fetchWrapper(promise, { showSlowAction: true, showProgress: false, ...options });
export const putApi = async (promise, options) => fetchWrapper(promise, { showSlowAction: true, showProgress: false, ...options });
export const deleteApi = async (promise, options) => fetchWrapper(promise, { showSlowAction: true, showProgress: false, ...options });
export const postMultipartApi = async (promise, options) =>
  fetchWrapper(promise, { showSlowAction: true, showProgress: false, ...options });
