import PropTypes from "prop-types";
import { Select } from "@utdanningsdirektoratet/select";
import { malgruppeShape } from "utils/PropTypes";
import { useIntl } from "react-intl";
import { Element } from "react-scroll";
import FieldName from "../FieldName";

const { REPRESENTERER, STILLING, FYLKE, KOMMUNE, UNDERTYPE, BEKREFTREPRESENTASJON } = FieldName;

const fieldsToReset = [REPRESENTERER, STILLING, FYLKE, KOMMUNE, UNDERTYPE, BEKREFTREPRESENTASJON];

const Sektor = ({ fields, malgrupper, resetFields }) => {
  const intl = useIntl();
  if (malgrupper.length <= 1) return null;

  const onChangeWrapped = (event) => {
    resetFields(fieldsToReset);
    fields.sektor.onChange(event);
    if (event.target.value) fields.malgruppe.onChange(malgrupper.filter((m) => m.sektor === event.target.value)[0].id);
  };

  const options = [...new Set(malgrupper.map((m) => m.sektor).sort())].map((sektor) => ({
    value: sektor,
    text: `${intl.formatMessage({ id: `sendInnPopin.${sektor.toLowerCase()}` })}`,
  }));

  const withDefault = [
    {
      value: "",
      text: `— ${intl.formatMessage({ id: "sendInnPopin.velg" })} ${intl.formatMessage({ id: "sendInnPopin.sektor" }).toLowerCase()} —`,
    },
    ...options,
  ];

  return (
    <div className="SendInnPopin-flexInput">
      <Element name={FieldName.SEKTOR} />
      <Select
        {...fields.sektor}
        className="SendInnPopin-select"
        onChange={onChangeWrapped}
        options={withDefault}
        id="minSektor"
        aria-label={intl.formatMessage({ id: "sendInnPopin.velgSektor" })}
      />
    </div>
  );
};

Sektor.propTypes = {
  fields: PropTypes.object.isRequired,
  malgrupper: PropTypes.arrayOf(malgruppeShape).isRequired,
  resetFields: PropTypes.func.isRequired,
};

export default Sektor;
