import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Checkbox } from "@utdanningsdirektoratet/checkbox";
import { Radio } from "@utdanningsdirektoratet/radio";
import { Input } from "@utdanningsdirektoratet/input";
import { validationErrorsShape } from "utils/PropTypes";
import { useIntl } from "react-intl";
import Obligatorisk from "./Obligatorisk";
import { svarAlternativShape } from "../../../../../utils/PropTypes";
import { getTextWithLocale } from "../../../../../utils/localeUtils";

export const TYPE_CHECK = "check";
export const TYPE_RADIO = "radio";

const SvarAlternativ = ({ id, alternativ, selected, egendefinertSvar, svarAlternativ, errors, disabled, obligatorisk, type, onChange }) => {
  const intl = useIntl();
  const hasErrors = errors.length > 0 && !disabled;
  const altErrors = errors.filter((egn) => egn.alternativId === id);
  const locale = useSelector((state) => state.locale);

  const check =
    type === TYPE_CHECK ? (
      <Checkbox
        checked={selected}
        name={`checkbox-alternativ-${id}`}
        label={alternativ.erEgendefinert ? intl.formatMessage({ id: "formLabels.annet" }) : getTextWithLocale(alternativ.tittel, locale)}
        disabled={disabled}
        error={hasErrors}
        onChange={() => onChange({ selected: !selected, egendefinert: egendefinertSvar })}
        className="SvarAlternativ-checkbox"
      />
    ) : (
      <Radio
        checked={selected}
        name={`radio-alternativ-${id}`}
        label={alternativ.erEgendefinert ? intl.formatMessage({ id: "formLabels.annet" }) : getTextWithLocale(alternativ.tittel, locale)}
        disabled={disabled}
        error={hasErrors}
        onChange={() => onChange({ selected: !selected, egendefinert: egendefinertSvar })}
        className="SvarAlternativ-radio"
      />
    );

  const svarAlternativClass = classnames({
    SvarAlternativ: true,
    "SvarAlternativ--obligatory": obligatorisk && svarAlternativ.length === 1,
  });

  const svarAlternativInputClass = classnames({
    "SvarAlternativ-input": true,
    "SvarAlternativ-input--egendefinert": alternativ.erEgendefinert,
  });

  const validationErrorMessagesMalform = altErrors.map((e) => intl.formatMessage(e.text)).join(" ");
  return (
    <div className={svarAlternativClass}>
      <div className={svarAlternativInputClass}>{check}</div>
      {alternativ.erEgendefinert ? (
        <Input
          aria-label={intl.formatMessage({ id: "formLabels.annetTekst" })}
          disabled={disabled}
          value={egendefinertSvar}
          className="SvarAlternativ-wrapper SvarAlternativ-wrapper-input--egendefinert"
          onFocus={() => onChange({ selected: true, egendefinert: egendefinertSvar })}
          onChange={(e) => onChange({ selected, egendefinert: e.target.value })}
          error={altErrors.length > 0}
          message={validationErrorMessagesMalform}
        />
      ) : null}
      {svarAlternativ.length === 1 ? <Obligatorisk isObligatorisk={obligatorisk}></Obligatorisk> : null}
    </div>
  );
};

SvarAlternativ.propTypes = {
  id: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  egendefinertSvar: PropTypes.string,
  obligatorisk: PropTypes.bool,
  disabled: PropTypes.bool,
  svarAlternativ: PropTypes.arrayOf(svarAlternativShape).isRequired,
  alternativ: svarAlternativShape,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf([TYPE_RADIO, TYPE_CHECK]),
  errors: validationErrorsShape.isRequired,
};

export default SvarAlternativ;
