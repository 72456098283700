import * as React from "react";
import { Link } from "@utdanningsdirektoratet/link";
import { useSelector } from "react-redux";
import styles from "./Innholdsfortegnelse.module.less";
import { scrollTo } from "../../../../utils/scrollUtils";
import { getTextWithLocale } from "../../../../utils/localeUtils";
import { Målform, MålformString } from "../../../../ApiClients";

type Seksjon = {
  tittel: MålformString;
  nummerering: string;
  niva: number;
  id: number;
};

type InnholdsfortegnelseProps = {
  seksjoner: Array<Seksjon>;
  skjulNummerering?: boolean;
};

const GetNiva = (seksjoner: Array<Seksjon>, skjulNummerering = false, locale: Målform, prevLevel = 0): React.ReactNode => {
  const current = seksjoner[0];
  if (!current) return null;

  const nextLevel = seksjoner.findIndex((s) => s.niva !== current.niva);
  const tittel = getTextWithLocale(current.tittel, locale);

  if (current.niva > prevLevel) {
    return (
      <>
        <ol>
          <li className={styles.innholdsfortegnelseItem}>
            <Link href={`#seksjon-${current.id}`} onClick={() => scrollTo(`seksjon-${current.id}`)}>
              {`${skjulNummerering ? tittel : `${current.nummerering} ${tittel}`}`}
            </Link>
          </li>
          {GetNiva(seksjoner.slice(1, nextLevel === -1 ? undefined : nextLevel), skjulNummerering, locale, current.niva)}
        </ol>
        {nextLevel !== -1 && GetNiva(seksjoner.slice(nextLevel), skjulNummerering, locale, seksjoner[nextLevel - 1].niva)}
      </>
    );
  }

  return (
    <>
      <li className={styles.innholdsfortegnelseItem}>
        <Link onClick={() => scrollTo(`seksjon-${current.id}`)} href={`#seksjon-${current.id}`}>
          {`${skjulNummerering ? tittel : `${current.nummerering} ${tittel}`}`}
        </Link>
      </li>
      {GetNiva(seksjoner.slice(1), skjulNummerering, locale, current.niva)}
    </>
  );
};

const Innholdsfortegnelse: React.FC<InnholdsfortegnelseProps> = ({ seksjoner = [], skjulNummerering = false }) => {
  const locale = useSelector<{ locale: Målform }>((state) => state.locale) as Målform;
  if (seksjoner.length === 0) return null;

  return (
    <ol className={styles.innholdsfortegnelse}>
      {GetNiva(
        seksjoner.filter((s) => s.niva <= 1),
        skjulNummerering,
        locale
      )}
    </ol>
  );
};

export default Innholdsfortegnelse;
