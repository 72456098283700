import * as React from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import { MultiSelect } from "@utdanningsdirektoratet/multiselect";
import { cls } from "@utdanningsdirektoratet/utils/cls";
import { fylker, enums } from "logic/initialState";
import { validationErrorsShape } from "utils/PropTypes";
import { useIntl, FormattedMessage } from "react-intl";
import Obligatorisk from "./Obligatorisk";

const onChangeDebounced = debounce((value, updateSporsmalUttalelse, sporsmalId) => {
  updateSporsmalUttalelse(sporsmalId, {
    fylkeKommuneSvar: { value },
  });
}, 3000);

const onChange = (value, updateSporsmalUttalelse, sporsmalId) => {
  onChangeDebounced(value, updateSporsmalUttalelse, sporsmalId);
  if (value) {
    updateSporsmalUttalelse(sporsmalId, {
      fylkeKommuneSvar: { value },
    });
  }
};

const FylkeKommuneSvar = ({
  readonly,
  fylkeKommune,
  sporsmalId,
  updateSporsmalUttalelse,
  forhandsvisning,
  fylkeKommuneSvar,
  validationErrors,
}) => {
  const intl = useIntl();

  const selectKommuner =
    fylkeKommune.type === enums.fylkeKommuneTypes.kommune || fylkeKommune.type === enums.fylkeKommuneTypes.fylkeKommune;

  const fylke = fylkeKommune.flereFylker !== true && fylkeKommuneSvar.value.length > 0 ? fylkeKommuneSvar.value[0].substr(0, 2) : "";

  const error = validationErrors.length > 0;
  const validationErrorMessagesMalform = validationErrors.map((err) => intl.formatMessage(err.text)).join("\n");
  const valgtFylke = fylker.find((f) => f.fylkesNr === fylke);
  const valgtKommune = React.useCallback(
    (fylkesNr, kommuneNr) =>
      fylker
        .find((f) => f.fylkesNr === fylkesNr)
        .kommuner.find((k) => k.kommuneNr !== kommuneNr && fylkeKommuneSvar.value.includes(k.kommuneNr)),
    [fylkeKommuneSvar.value]
  );

  const fylkeOptions = React.useMemo(
    () =>
      fylker
        .filter((f) => f.aktiv)
        .reduce(
          (acc, f) => ({
            ...acc,
            [f.navn]: {
              value: f.fylkesNr,
              disabled: valgtFylke != null && valgtFylke.fylkesNr !== f.fylkesNr,
              options: !selectKommuner
                ? []
                : (f.kommuner || [])
                    .filter((k) => k.aktiv)
                    .map((k) => ({
                      text: k.navn,
                      value: k.kommuneNr,
                      disabled: fylkeKommune.flereKommuner !== true && valgtKommune(f.fylkesNr, k.kommuneNr) != null,
                    })),
            },
          }),
          {}
        ),
    [selectKommuner, valgtFylke, valgtKommune, fylkeKommune]
  );

  const valgteFylker = fylker.filter(
    (f) => fylkeKommuneSvar.value.includes(f.fylkesNr) || f.kommuner.some((k) => fylkeKommuneSvar.value.includes(k.kommuneNr))
  );
  const valgteKommuner = fylker
    .flatMap((f) => f.kommuner)
    .filter((k) => fylkeKommuneSvar.value.includes(k.kommuneNr))
    .map((m) => m.navn);

  let valgte = [];
  if (fylkeKommune.type === enums.fylkeKommuneTypes.fylkeKommune) valgte = [...valgteFylker.map((f) => f.navn), ...valgteKommuner];
  if (fylkeKommune.type === enums.fylkeKommuneTypes.fylke) valgte = valgteFylker.map((f) => f.navn);
  if (fylkeKommune.type === enums.fylkeKommuneTypes.kommune) valgte = valgteKommuner;

  let placeholder = "formLabels.velgFylkeKommune";
  if (fylkeKommune.type === enums.fylkeKommuneTypes.kommune) placeholder = "formLabels.velgKommune";
  if (fylkeKommune.type === enums.fylkeKommuneTypes.fylke) placeholder = "formLabels.velgFylke";

  const listClass = cls({
    "Sporsmal-liste": fylkeKommuneSvar.value.some((s) => s.length === 4),
  });

  if (!forhandsvisning && readonly) {
    if (fylkeKommuneSvar.value) {
      return (
        <div className="Sporsmal-body">
          <ul className={listClass}>
            {valgteFylker.map((f) => {
              const _valgteKommuner = f.kommuner.filter((k) => fylkeKommuneSvar.value.includes(k.kommuneNr));
              return (
                <li key={f.fylkesNr} className="Sporsmal-list-item">
                  <strong>{f.navn}</strong>
                  {_valgteKommuner.length > 0 && (
                    <ul>
                      {_valgteKommuner.map((k) => (
                        <li key={k.kommuneNr}>{k.navn}</li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
    return (
      <div className="Sporsmal-body Sporsmal-body--italic">
        <FormattedMessage id="validation.ikkeAngittFylkeKommune" />
      </div>
    );
  }

  return (
    <div className="Sporsmal-body">
      <Obligatorisk isObligatorisk={fylkeKommune.obligatorisk}>
        <MultiSelect
          className="Sporsmal-fylkeKommune"
          label=""
          placeholder={valgte.sort().join(", ") || `— ${intl.formatMessage({ id: placeholder })} —`}
          onChange={(value) => onChange(value, updateSporsmalUttalelse, sporsmalId)}
          options={fylkeOptions}
          error={error}
          message={validationErrorMessagesMalform}
          disabled={readonly}
          value={fylkeKommuneSvar.value}
        />
      </Obligatorisk>
    </div>
  );
};

FylkeKommuneSvar.propTypes = {
  sporsmalId: PropTypes.number.isRequired,
  updateSporsmalUttalelse: PropTypes.func.isRequired,
  fylkeKommuneSvar: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  readonly: PropTypes.bool.isRequired,
  fylkeKommune: PropTypes.shape({
    obligatorisk: PropTypes.bool,
    type: PropTypes.string,
    flereFylker: PropTypes.bool,
    flereKommuner: PropTypes.bool,
  }).isRequired,
  forhandsvisning: PropTypes.bool.isRequired,
  validationErrors: validationErrorsShape.isRequired,
};

export default FylkeKommuneSvar;
