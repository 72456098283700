import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from 'reducers';
import { autoSaveMiddleWare } from 'ducks/autoSaveDuck';
import { validateOppgitt } from 'ducks/validationDuck';
import * as Sentry from '@sentry/react';

export default function configureStore(initialState = {}, history) {
  const middleware = applyMiddleware(
    thunk,
    routerMiddleware(history),
    autoSaveMiddleWare,
    createLogger({
      predicate: () => __DEV__
    })
  );
  const reducer = combineReducers({
    router: connectRouter(history),
    ...rootReducer
  });

  const sentryReduxEnhancer = Sentry.createReduxEnhancer();

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducer, initialState, composeEnhancers(
    middleware, sentryReduxEnhancer
  ));

  if (initialState.hoering) {
    validateOppgitt()(store.dispatch, store.getState);
  }

  return store;
}
