import { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import PopperJS from "popper.js";
import MobileDetect from "mobile-detect";
import debounce from "lodash/debounce";
import { injectIntl } from "react-intl";
import { Button } from "@utdanningsdirektoratet/button";
import { Icon } from "@utdanningsdirektoratet/icon";
import { Textarea } from "@utdanningsdirektoratet/textarea";

const onBlurWrapper = (onBlur) => (e) => {
  if (onBlur) {
    onBlur(e);
  }
};

class Tooltip extends PureComponent {
  componentDidMount() {
    this.save = debounce(onBlurWrapper(this.props.onBlur), 500);
    this.md = new MobileDetect(window.navigator.userAgent);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.container || !nextProps.comment || !nextProps.edit) {
      clearTimeout(this.timeout);
    }
  }

  componentDidUpdate(prevProps) {
    this.updatePopper();

    if (!prevProps.edit && this.props.edit) {
      this.focus();
    }
  }

  componentWillUnmount() {
    this.destroyPopper();
    clearTimeout(this.timeout);
  }

  onMouseUp = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  onChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
    this.save(e);
  };

  onKeyDown = (e) => {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
    this.update();
  };

  cleanHtml = (html) => {
    if (!html) return "";
    return html
      .replace(/<br( ?\/)?>/gi, "\n") // Convert v1 comments to v2
      .replace(/(\n){2,}/gi, "\n\n");
  };

  createPopper = () => {
    const { comment, container } = this.props;
    if (comment && container) {
      this.popper = new PopperJS(container, this.tooltip, {
        placement: this.md.mobile() ? "bottom" : "top",
        eventsEnabled: true,
        removeOnDestroy: false,
        modifiers: {
          flip: { enabled: false },
          offset: {
            fn: (data) => {
              if (this.md.mobile()) {
                data.offsets.popper.top += 8; // eslint-disable-line no-param-reassign
              } else {
                data.offsets.popper.top -= 8; // eslint-disable-line no-param-reassign
              }
              return data;
            },
          },
        },
        onCreate: this.focus,
      });

      this.update();
    }
  };

  focus = () => {
    this.timeout = setTimeout(() => {
      if (this.textarea && this.props.edit) {
        this.textarea.focus();
      }
    }, 100);
  };

  destroyPopper = () => {
    if (this.popper) {
      this.popper.destroy();
      this.popper = null;
    }
  };

  updatePopper = () => {
    this.destroyPopper();
    this.createPopper();
  };

  update = () => {
    if (this.popper) {
      this.popper.scheduleUpdate();
    } else {
      this.updatePopper();
    }
  };

  render() {
    const { edit, comment, container, onClick, onBlur, intl } = this.props;

    if (!comment && !container) return null;

    const tooltipClass = classnames({
      "MarkerKommentar-tooltip": true,
      "MarkerKommentar-tooltip--active": edit,
      "MarkerKommentar-tooltip--mobile": this.md.mobile(),
    });

    const textareaClass = classnames({
      "MarkerKommentar-textarea": true,
      "MarkerKommentar-textarea--active": edit,
    });

    return (
      <div // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
        onMouseUp={this.onMouseUp}
        onTouchStart={this.onMouseUp}
        className={tooltipClass}
        aria-live="polite"
        role="tooltip"
        aria-label={comment.kommentar}
        aria-hidden={false}
        ref={(tooltip) => {
          this.tooltip = tooltip;
        }}
      >
        <Textarea
          ref={(textarea) => {
            this.textarea = textarea;
          }}
          className={textareaClass}
          placeholder={intl.formatMessage({ id: "formLabels.skrivKommentar" })}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onBlur={onBlurWrapper(onBlur)}
          value={this.cleanHtml(comment.kommentar)}
          disabled={!edit}
          rows="1"
          autoGrow
        />
        {edit ? (
          <Button onClick={onClick} onTouchStart={onClick} className="MarkerKommentar-button" inline>
            <Icon icon="trash" type="small" />
          </Button>
        ) : null}
      </div>
    );
  }
}

Tooltip.propTypes = {
  comment: PropTypes.object,
  edit: PropTypes.bool.isRequired,
  container: PropTypes.any,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  intl: PropTypes.object,
};

export default injectIntl(Tooltip);
