import { validateSporsmal, validateSeksjon, validateNotat, isSporsmalBesvart, isSeksjonBesvart, isNotatBesvart } from 'logic/validation';
import { UPDATE_SPORSMAL_UTTALELSE, UPDATE_SEKSJON_UTTALELSE, UPDATE_NOTAT_UTTALELSE, getSporsmalUttalelse, getSeksjonUttalelse, getNotatUttalelse } from './uttalelseDuck';

const VALIDATE_ALL = 'validation/ALL';
const VALIDATE_NOTAT = 'validation/NOTAT';
const VALIDATE_OPPGITT = 'validation/OPPGITT';

const filterAndReduce = (validations) => validations
  .filter((u) => u && u.errors && u.errors.length > 0)
  .reduce((st, u) => ({ ...st, [u.id]: u.errors }), {});

const validateUttalelser = (uttalelser, validate, getUttalelser, uttalelses) => Object.entries(uttalelser).map(([id, uttalelse]) => ({ id, errors: validate(uttalelse, getUttalelser(id, uttalelses)) }));
const validateOppgittUttalelser = (uttalelses, isBesvart, validate, uttalelser) => Object.entries(uttalelses).filter(([, uttalelse]) => isBesvart(uttalelse)).map(([id, uttalelse]) => ({ id, errors: validate(uttalelser[id], uttalelse) }));

const reducer = (state = {}, action) => {
  const pl = action.payload;
  switch (action.type) {
    case UPDATE_SPORSMAL_UTTALELSE:
      return { ...state, sporsmal: { ...state.sporsmal, [pl.sporsmalId]: validateSporsmal(pl.sporsmal, { ...pl.oldState, ...pl.uttalelse }) } };

    case UPDATE_SEKSJON_UTTALELSE:
      return { ...state, seksjoner: { ...state.seksjoner, [pl.seksjonId]: validateSporsmal(pl.seksjon, { ...pl.oldState, ...pl.uttalelse }) } };

    case UPDATE_NOTAT_UTTALELSE:
      return { ...state, notater: { ...state.notater, [pl.notatId]: validateSporsmal(pl.notat, { ...pl.oldState, ...pl.uttalelse }) } };

    case VALIDATE_ALL:
      return {
        ...state,
        sporsmal: filterAndReduce(validateUttalelser(pl.sporsmal, validateSporsmal, getSporsmalUttalelse, pl.uttalelses)),
        seksjoner: filterAndReduce(validateUttalelser(pl.seksjoner, validateSeksjon, getSeksjonUttalelse, pl.uttalelses)),
        notater: filterAndReduce(validateUttalelser(pl.notater, validateNotat, getNotatUttalelse, pl.uttalelses))
      };

    case VALIDATE_NOTAT:
      const currentNotat = { [pl.id]: pl.notater[pl.id] };
      const currentSeksjons = Object.values(pl.seksjoner).filter((s) => s.notatId === pl.id).reduce((val, curr) => ({ [curr.id]: curr, ...val }), {});
      const currentSporsmals = Object.values(pl.sporsmal).filter((s) => s.notatId === pl.id).reduce((val, curr) => ({ [curr.id]: curr, ...val }), {});
      return {
        ...state,
        sporsmal: filterAndReduce(validateUttalelser(currentSporsmals, validateSporsmal, getSporsmalUttalelse, pl.uttalelses)),
        seksjoner: filterAndReduce(validateUttalelser(currentSeksjons, validateSeksjon, getSeksjonUttalelse, pl.uttalelses)),
        notater: filterAndReduce(validateUttalelser(currentNotat, validateNotat, getNotatUttalelse, pl.uttalelses))
      };

    case VALIDATE_OPPGITT:
      return {
        ...state,
        sporsmal: filterAndReduce(validateOppgittUttalelser(pl.uttalelses.sporsmal, isSporsmalBesvart, validateSporsmal, pl.sporsmal)),
        seksjoner: filterAndReduce(validateOppgittUttalelser(pl.uttalelses.seksjoner, isSeksjonBesvart, validateSeksjon, pl.seksjoner)),
        notater: filterAndReduce(validateOppgittUttalelser(pl.uttalelses.notater, isNotatBesvart, validateNotat, pl.notater))
      };
    default:
      return state;
  }
};

export const getValidation = (id, state) => state[id] || [];
export const firstInvalidId = (state) => {
  const result = {};
  Object.keys(state).forEach((key) => {
    result[key] = Object.keys(state[key])
      .map((id) => ({ id, errors: getValidation(id, state[key]) }))
      .filter((v) => v.errors.length > 0)
      .map(({ id }) => parseInt(id, 10))
      .sort((a, b) => a - b)
      .find((id) => id);
  });
  return result;
};

export const filterByType = (type, validation = []) => validation.filter((v) => v.type === type);
const dispatchUttalelses = (type) => (id) => (dispatch, getState) => dispatch({
  type,
  payload: {
    id,
    uttalelses: getState().uttalelse,
    sporsmal: getState().sporsmal,
    seksjoner: getState().seksjon,
    notater: getState().notat
  }
});
export const validateAll = dispatchUttalelses(VALIDATE_ALL);
export const validateOppgittNotat = dispatchUttalelses(VALIDATE_NOTAT);
export const validateOppgitt = dispatchUttalelses(VALIDATE_OPPGITT);

export default reducer;
