import { postMultipartApi, deleteApi } from 'api/fetchWrapper';
import { saveNow } from 'ducks/autoSaveDuck';
import { newErrorNotification } from 'ducks/notificationsDuck';
import { HoeringClient } from '../../../../../../ApiClients';

const client = new HoeringClient();

const getFormData = (files) => {
  return files.map((file) => ({ fileName: file.name, data: file }));
};

export const lastOppVedleggManueltRegistrert = (sporsmalId, files, eksternId, callback, errorCallback) => {
  const body = getFormData(files);
  postMultipartApi(client.lastOppSporsmaalUttalelseVedlegg(body, sporsmalId, eksternId))
    .then((result) => {
      callback(result);
    })
    .catch(() => {
      errorCallback();
    });
};

export const lastOppVedlegg = (sporsmalId, files, callback, errorCallback) => {
  return (dispatch) => {
    saveNow()(dispatch)
      .then((e) => {
        const body = getFormData(files);

        postMultipartApi(client.lastOppSporsmaalUttalelseVedlegg(body, sporsmalId, e.payload.eksternId))
          .then((result) => {
            callback(result);
          })
          .catch(() => {
            errorCallback();
          });
      })
      .catch((error) => {
        dispatch(newErrorNotification(error.title, error.message));
      });
  };
};

export const slettVedlegg = (sporsmalId, eksternId, callback, errorCallback) => {
  return () => {
    deleteApi(client.slettSporsmaalUttalelseVedlegg(eksternId))
      .then((result) => {
        callback(result);
      })
      .catch(() => {
        errorCallback();
      });
  };
};
