import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Input } from "@utdanningsdirektoratet/input";
import { AccountInput, OrganisasjonsnummerInput } from "@utdanningsdirektoratet/maskedinput";
import { validationErrorsShape } from "utils/PropTypes";
import { useIntl, FormattedMessage } from "react-intl";
import { KortSvarTypes } from "constants/serverEnums";
import Obligatorisk from "./Obligatorisk";

const KortTekstSvar = (props) => {
  const search = new URLSearchParams(window.location.search);
  const { readonly, obligatorisk, forhandsvisning, kortTekstSvar, validationErrors, type, sporsmalId, updateSporsmalUttalelse } = props;
  const [value, setValue] = useState(kortTekstSvar.value || search.get("orgnr"));
  const intl = useIntl();

  useEffect(() => {
    if (search.get("orgnr") && type === KortSvarTypes.Orgnr) {
      updateSporsmalUttalelse(
        sporsmalId,
        {
          kortTekstSvar: { value: search.get("orgnr") },
        },
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (kortTekstSvar.value) setValue(kortTekstSvar.value);
  }, [kortTekstSvar.value]);

  const onChange = (e) => {
    setValue(e.target ? e.target.value : e);
  };

  const onBlur = (e) => {
    updateSporsmalUttalelse(sporsmalId, {
      kortTekstSvar: { value: e.target ? e.target.value : e },
    });
  };

  const error = validationErrors.length > 0;
  const validationErrorMessagesMalform = validationErrors.map((err) => intl.formatMessage(err.text)).join("\n");

  const kortTextClass = classnames({
    "Sporsmal-input": true,
    "Sporsmal-korttekst": true,
    "Sporsmal-korttekst--wide": kortTekstSvar.value.length >= 42,
    "Sporsmal-korttekst--narrow": type !== KortSvarTypes.Tekst,
  });

  const oblicatoriskClass = classnames({
    "Obligatorisk--block": kortTekstSvar.value.length >= 42,
  });

  if (!forhandsvisning && readonly) {
    if (kortTekstSvar.value) {
      return <div className="Sporsmal-body">{kortTekstSvar.value}</div>;
    }
    return (
      <div className="Sporsmal-body Sporsmal-body--italic">
        <FormattedMessage id="validation.ikkeAngittKortSvar" />
      </div>
    );
  }

  const inputProps = {
    className: kortTextClass,
    error,
    message: validationErrorMessagesMalform,
    disabled: readonly,
    value,
    onChange,
    onBlur,
    icon: "warning",
    placeholder: intl.formatMessage({ id: "formLabels.skrivHer" }),
  };

  let input;
  switch (type) {
    case KortSvarTypes.Kontonr:
      input = <AccountInput {...inputProps} aria-label="Kontonummer" />;
      break;
    case KortSvarTypes.Orgnr:
      input = <OrganisasjonsnummerInput {...inputProps} aria-label="Organisasjonsnummer" />;
      break;
    case KortSvarTypes.Epost:
      input = <Input {...inputProps} aria-label="E-post" />;
      break;
    case KortSvarTypes.Tekst:
    default:
      input = <Input {...inputProps} onChange={onBlur} maxLength="4000" aria-label="Tekst" />;
      break;
  }

  return (
    <div className="Sporsmal-body">
      <Obligatorisk className={oblicatoriskClass} isObligatorisk={obligatorisk}>
        {input}
      </Obligatorisk>
    </div>
  );
};

KortTekstSvar.propTypes = {
  sporsmalId: PropTypes.number.isRequired,
  updateSporsmalUttalelse: PropTypes.func.isRequired,
  kortTekstSvar: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  type: PropTypes.number,
  readonly: PropTypes.bool.isRequired,
  obligatorisk: PropTypes.bool,
  forhandsvisning: PropTypes.bool.isRequired,
  validationErrors: validationErrorsShape.isRequired,
  intl: PropTypes.object,
};

export default KortTekstSvar;
