import { Route, Switch } from "react-router";
import Progress from "react-progress-2";
import { SlowAction } from "@utdanningsdirektoratet/slowaction";
import { PopinManager } from "@utdanningsdirektoratet/popin";
import * as Sentry from "@sentry/react";

import withLayout from "components/Layout";
import Uttalelse from "./pages/uttalelse";
import Default from "./pages/default";

import "@formatjs/intl-getcanonicallocales/polyfill";
import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/nb";
import "@formatjs/intl-pluralrules/locale-data/nn";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/nb";
import "@formatjs/intl-relativetimeformat/locale-data/nn";

const SentryRoute = Sentry.withSentryRouting(Route);

const App = () => {
  return (
    <>
      <Progress.Component />
      <Switch>
        <SentryRoute
          exact
          path={[
            "/Hoering/:hoeringId",
            "/Hoering/v2/:hoeringId",
            "/Uttalelse/:eksternId",
            "/Uttalelse/v2/:eksternId",
            "/LesUtkast/:leseTilgangsKode",
            "/LesUtkast/v2/:leseTilgangsKode",
            "/Utkast/:eksternId",
            "/Utkast/v2/:eksternId",
            "/Forhaandsvisning/:hoeringId/:forhaandsVisningsKode",
            "/Forhaandsvisning/v2/:hoeringId/:forhaandsVisningsKode",
            "/ManuellRegistrering/:eksternId/:manuellRegistreringsKode",
            "/ManuellRegistrering/v2/:eksternId/:manuellRegistreringsKode",
            "/LukketInnsending/:hoeringId/:innsendingsKode",
            "/LukketInnsending/v2/:hoeringId/:innsendingsKode",
          ]}
          component={withLayout(Uttalelse)}
        />
        <SentryRoute component={withLayout(Default)} />
      </Switch>
      <PopinManager />
      <SlowAction />
    </>
  );
};

export default App;
