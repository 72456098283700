import { show } from "@utdanningsdirektoratet/popin";
import { postApi } from "api/fetchWrapper";
import mapVm from "vm/mapVm";
import { sendInnPopinId, lagreUtkastPopinId, videresendUtkastPopinId } from "constants/popinIds";
import { validateAll, validateOppgittNotat, firstInvalidId } from "ducks/validationDuck";
import { saveNow } from "ducks/autoSaveDuck";
import { newErrorNotification } from "ducks/notificationsDuck";
import {
  navigateToNotat as navigateToNotatAction,
  navigateToSeksjon as navigateToSeksjonAction,
  navigateToSporsmal as navigateToSporsmalAction,
  navigateToFootnote as navigateToFootnoteAction,
} from "ducks/navigationDuck";

import {
  setIngenMerknader,
  updateSporsmalUttalelseInit as updateSporsmalUttalelseInitAction,
  updateSporsmalUttalelse as updateSporsmalUttalelseAction,
  updateSeksjonUttalelse as updateSeksjonUttalelseAction,
  updateNotatUttalelse as updateNotatUttalelseAction,
  getSporsmalUttalelse,
  getSeksjonUttalelse,
  getNotatUttalelse,
} from "ducks/uttalelseDuck";

import { HoeringClient } from "../../ApiClients";

const client = new HoeringClient();

const isValid = (dispatch, getState) => {
  const invalid = firstInvalidId(getState().validation);

  if (invalid.sporsmal) {
    navigateToSporsmalAction(invalid.sporsmal)(dispatch, getState);
    return false;
  }

  if (invalid.seksjoner) {
    navigateToSeksjonAction(invalid.seksjoner)(dispatch, getState);
    return false;
  }

  if (invalid.notater) {
    navigateToNotatAction(invalid.notater, true)(dispatch, getState);
    return false;
  }

  return true;
};

export const ingenMerknader = () => {
  return (dispatch) => {
    dispatch(setIngenMerknader(true));
    show(sendInnPopinId);
  };
};

export const ingenMerknaderManuellRegistrert = (eksternId, manuellRegistreringsKode, value) => {
  return (dispatch) => {
    dispatch(setIngenMerknader(value));
    postApi(client.setIngenMerknader(eksternId, manuellRegistreringsKode, value))
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        dispatch(newErrorNotification(error.title, error.message));
      });
  };
};

export const showLagreOgSendInn = () => {
  return (dispatch, getState) => {
    dispatch(setIngenMerknader(false));
    validateAll()(dispatch, getState);
    if (isValid(dispatch, getState)) {
      show(sendInnPopinId);
    }
  };
};

export const lagreOgGaVidere = (currentNotatId, nextNotatId) => {
  return (dispatch, getState) => {
    dispatch(setIngenMerknader(false));
    validateOppgittNotat(currentNotatId)(dispatch, getState);
    if (isValid(dispatch, getState)) {
      navigateToNotatAction(nextNotatId)(dispatch, getState);
    }
  };
};

export const navigateToNotat = (notatId, scrollTo) => {
  return (dispatch, getState) => {
    navigateToNotatAction(notatId, scrollTo)(dispatch, getState);
  };
};

export const navigateToFootnote = (fotnoteId, notatId) => {
  return (dispatch, getState) => {
    navigateToFootnoteAction(fotnoteId, notatId)(dispatch, getState);
  };
};

export const lagreOgGaTilbake = (eksternId, uttalelse, hoering, manuellRegistreringsKode, returnTo) => {
  return (dispatch, getState) => {
    const vm = mapVm(eksternId, uttalelse, getState().initialUttalelse, hoering, getState().locale);
    postApi(client.lagreManuellRegistrering(hoering.hoeringId, eksternId, manuellRegistreringsKode, vm))
      .then(() => {
        window.location = returnTo;
      })
      .catch((error) => {
        dispatch(newErrorNotification(error.title, error.message));
      });
  };
};

export const showLagreUtkast = () => {
  return (dispatch) => {
    saveNow()(dispatch)
      .then(() => {
        show(lagreUtkastPopinId);
      })
      .catch((error) => {
        dispatch(newErrorNotification(error.title, error.message));
      });
  };
};

export const showVideresendUtkast = () => {
  return (dispatch) => {
    saveNow()(dispatch)
      .then(() => {
        show(videresendUtkastPopinId);
      })
      .catch((error) => {
        dispatch(newErrorNotification(error.title, error.message));
      });
  };
};

export const updateSporsmalUttalelse = (sporsmalId, uttalelse, init = false) => {
  return (dispatch, getState) => {
    if (init) {
      dispatch(
        updateSporsmalUttalelseInitAction(
          sporsmalId,
          uttalelse,
          getState().sporsmal[sporsmalId],
          getSporsmalUttalelse(sporsmalId, getState().uttalelse)
        )
      );
    } else {
      dispatch(
        updateSporsmalUttalelseAction(
          sporsmalId,
          uttalelse,
          getState().sporsmal[sporsmalId],
          getSporsmalUttalelse(sporsmalId, getState().uttalelse)
        )
      );
    }
  };
};

export const updateSeksjonUttalelse = (seksjonId, uttalelse) => {
  return (dispatch, getState) => {
    dispatch(
      updateSeksjonUttalelseAction(
        seksjonId,
        uttalelse,
        getState().seksjon[seksjonId],
        getSeksjonUttalelse(seksjonId, getState().uttalelse)
      )
    );
  };
};

export const updateNotatUttalelse = (notatId, uttalelse) => {
  return (dispatch, getState) => {
    dispatch(updateNotatUttalelseAction(notatId, uttalelse, getState().notat[notatId], getNotatUttalelse(notatId, getState().uttalelse)));
  };
};
