import PropTypes from "prop-types";
import { Input } from "@utdanningsdirektoratet/input";
import { ObligatoriskhetEnum } from "constants/serverEnums";
import { malgruppeShape } from "utils/PropTypes";
import { useIntl } from "react-intl";
import { Element } from "react-scroll";
import FieldName from "../FieldName";

const Representerer = ({ fields, valgtMalgruppe }) => {
  const intl = useIntl();
  const visRepresenterer =
    valgtMalgruppe.innsenderRepresentererObligatoriskhet === ObligatoriskhetEnum.Obligatorisk ||
    valgtMalgruppe.innsenderRepresentererObligatoriskhet === ObligatoriskhetEnum.Valgfritt;

  if (!visRepresenterer) return null;

  const representererLabel =
    valgtMalgruppe.innsenderRepresentererObligatoriskhet !== ObligatoriskhetEnum.Obligatorisk
      ? `${intl.formatMessage({ id: "sendInnPopin.valgfritt" })}`
      : null;

  const label = valgtMalgruppe.innsenderRepresentererEtikett
    ? valgtMalgruppe.innsenderRepresentererEtikett
    : intl.formatMessage({ id: "sendInnPopin.navnPa" }, { 0: valgtMalgruppe.tittel.toLowerCase() });

  return (
    <div className="Grid-12-12">
      <Element name={FieldName.REPRESENTERER} />
      <label className="SendInnPopin-label" htmlFor="representerer">
        {label}
      </label>
      <Input
        {...fields.representerer}
        id="representerer"
        className="SendInnPopin-input"
        placeholder={representererLabel}
        aria-label={label}
      />
    </div>
  );
};

Representerer.propTypes = {
  fields: PropTypes.object.isRequired,
  valgtMalgruppe: malgruppeShape.isRequired,
};

export default Representerer;
