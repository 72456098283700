import PropTypes from "prop-types";
import classnames from "classnames";
import { Icon } from "@utdanningsdirektoratet/icon";

const Obligatorisk = ({ children, className, isObligatorisk, withLabel }) => {
  if (!isObligatorisk) return children || null;

  const obligatoriskClass = classnames(
    {
      Obligatorisk: true,
      "Obligatorisk--withLabel": withLabel,
      "Obligatorisk--empty": !children,
    },
    className
  );

  return (
    <span className={obligatoriskClass}>
      {children}
      {isObligatorisk ? <Icon className="Obligatorisk-icon" icon="asterix" /> : null}
    </span>
  );
};

Obligatorisk.propTypes = {
  isObligatorisk: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Obligatorisk;
