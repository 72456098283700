import "less/base.less";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { ConnectedRouter } from "connected-react-router";
import * as Sentry from "@sentry/react";
import initialState, { sentry } from "./logic/initialState";

import configureStore from "./configureStore";

import App from "./App";

const history = createBrowserHistory();
const store = configureStore(initialState(), history);

Sentry.init({
  dsn: sentry,
  integrations: [
    Sentry.replayIntegration(),
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ["hflhss"],
      behaviour: "drop-error-if-contains-third-party-frames",
    }),
  ],
  tracesSampleRate: 0,
  normalizeDepth: 10,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  tunnel: "/tunnel",
  release: `hoering@${process.env.PACKAGE_VERSION}`,
});

ReactDOM.render(
  <Sentry.ErrorBoundary>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById("app"), // eslint-disable-line
);
