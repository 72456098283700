import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentMetadata from 'components/DocumentMetadata';
import Footer from 'components/Footer';
import { FormattedMessage, useIntl } from 'react-intl';
import { institusjonLongName } from 'logic/initialState';

const Default = ({ page }) => {
  const intl = useIntl();
  let content;

  switch (page) {
    case 'servererror':
      content = (
        <h1 className="Page-title u-h1">
          <FormattedMessage id="error.detharOppstattEnFeil" />
        </h1>
      );
      break;
    case 'notfound':
      content = (
        <h1 className="Page-title u-h1">
          <FormattedMessage id="error.sidenFinnesIkke" />
        </h1>
      );
      break;
    case 'cookies':
      content = (
        <>
          <h1 className="Page-title u-h1"><FormattedMessage id="cookies.cookiesTittel" /></h1>

          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'cookies.cookiesTeknisk' }) }}
            />

          <h2 className="u-h2"><FormattedMessage id="cookies.cookiesMellomlagringOverskrift" /></h2>
          <p><FormattedMessage id="cookies.cookiesMellomlagring" /></p>

          <h2 className="u-h2"><FormattedMessage id="cookies.cookiesStatistikkOverskrift" /></h2>
          <p><FormattedMessage id="cookies.cookiesStatistikk" /></p>
        </>
      );
      break;
    default:
      content = (
        <h1 className="Page-title u-h1"><FormattedMessage id="default.forInformasjonOmHoeringerKlikkHer" /></h1>
      );
      break;
  }

  const search = new URLSearchParams(window.location.search);

  return (
    <>
      <DocumentMetadata title={institusjonLongName} />
      <div className="Grid">
        <div className="Page">
          <main id="main" className="Page-section u--marginTop4">
            {content}
          </main>
        </div>
      </div>
      <Footer hoeringId={search.get('hoeringId')} />
    </>
  );
};

Default.propTypes = {
  page: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(Default);
