import { useState } from "react";
import { useSelector, connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Popin } from "@utdanningsdirektoratet/popin";
import { useFormValidation, validationFunctions } from "@utdanningsdirektoratet/form";
import { hoeringShape } from "utils/PropTypes";
import { ObligatoriskhetEnum, GeografiObligatoriskhetEnum } from "constants/serverEnums";
import { epostValidation } from "logic/validation";
import FieldName from "./FieldName";
import * as serverActions from "./serverActions";
import SendInnForm from "./components/SendInnForm";

const SendInnPopin = (props) => {
  const { id, hoering, uttalelse, actions, ...rest } = props;
  const intl = useIntl();

  const userMalform = useSelector((state) => state.locale);

  const [representererEnkeltperson, setRepresenterer] = useState();

  const bekreftRepresentasjonRequired = (valgtMalgruppe) => valgtMalgruppe.bekreftRepresentasjon;

  const fylkeRequired = (valgtMalgruppe) =>
    valgtMalgruppe.geografiObligatoriskhet === GeografiObligatoriskhetEnum.FylkeOgKommuneObligatorisk ||
    valgtMalgruppe.geografiObligatoriskhet === GeografiObligatoriskhetEnum.FylkeObligatorisk;

  const kommuneRequired = (valgtMalgruppe) =>
    valgtMalgruppe.geografiObligatoriskhet === GeografiObligatoriskhetEnum.FylkeOgKommuneObligatorisk;

  const representererRequired = (valgtMalgruppe) =>
    valgtMalgruppe.innsenderRepresentererObligatoriskhet === ObligatoriskhetEnum.Obligatorisk;

  const stillingRequired = (valgtMalgruppe) => valgtMalgruppe.innsenderStillingObligatoriskhet === ObligatoriskhetEnum.Obligatorisk;

  const malgruppeHasValue = (values) => {
    const { malgruppe } = values;
    return malgruppe && malgruppe !== "" && malgruppe !== "null";
  };

  const popinTitle = uttalelse.ingenMerknader ? (
    <FormattedMessage id="sendInnPopin.viHarIngenMerknaderTilHoeringen" />
  ) : (
    <FormattedMessage id="sendInnPopin.title" />
  );

  const { malgrupper } = props.hoering;
  const valgtMalgruppe = (values) => {
    const { malgruppe } = values;
    return malgrupper.find((m) => m.id === parseInt(malgruppe, 10));
  };
  const sektorer = [...new Set(malgrupper.map((m) => m.sektor))];
  const formValidation = useFormValidation({
    [FieldName.NAVN]: {
      value: "",
      validation: [{ ...validationFunctions.required, message: intl.formatMessage({ id: "validation.navnErObligatorisk" }) }],
    },
    [FieldName.EPOST]: {
      value: "",
      validation: [
        { ...validationFunctions.required, message: intl.formatMessage({ id: "validation.epostErObligatorisk" }) },
        { ...epostValidation, message: intl.formatMessage(epostValidation.message) },
      ],
    },
    [FieldName.SEKTOR]: {
      value: sektorer.length === 1 ? sektorer[0] : undefined,
      validation: [{ ...validationFunctions.required, message: intl.formatMessage({ id: "validation.sektorErObligatorisk" }) }],
    },
    [FieldName.MALGRUPPE]: {
      value: malgrupper.length === 1 ? malgrupper[0].id : undefined,
      validation: [{ ...validationFunctions.numberRequired, message: intl.formatMessage({ id: "validation.malgruppeErObligatorisk" }) }],
    },
    [FieldName.REPRESENTERER]: {
      value: "",
      validation: [
        {
          validation: (input, values) =>
            !malgruppeHasValue(values) || !representererRequired(valgtMalgruppe(values)) || validationFunctions.required.validation(input),
          message: (value, values) =>
            intl.formatMessage(
              { id: "validation.representererErObligatorisk" },
              { 0: `${valgtMalgruppe(values).innsenderRepresentererEtikett}` }
            ),
        },
      ],
    },
    [FieldName.FYLKE]: {
      value: "",
      validation: [
        {
          validation: (input, values) =>
            !malgruppeHasValue(values) || !fylkeRequired(valgtMalgruppe(values)) || validationFunctions.numberRequired.validation(input),
          message: intl.formatMessage({ id: "validation.fylkeErObligatorisk" }),
        },
      ],
    },
    [FieldName.KOMMUNE]: {
      value: "",
      validation: [
        {
          validation: (input, values) =>
            !malgruppeHasValue(values) || !kommuneRequired(valgtMalgruppe(values)) || validationFunctions.numberRequired.validation(input),
          message: intl.formatMessage({ id: "validation.kommuneErObligatorisk" }),
        },
      ],
    },
    [FieldName.STILLING]: {
      value: "",
      validation: [
        {
          validation: (input, values) =>
            !malgruppeHasValue(values) || !stillingRequired(valgtMalgruppe(values)) || validationFunctions.required.validation(input),
          message: intl.formatMessage({ id: "validation.navnPaStillingErObligatorisk" }),
        },
      ],
    },
    [FieldName.UNDERTYPE]: {
      value: {},
      validation: [
        {
          validation: (input, values) =>
            !malgruppeHasValue(values) ||
            !valgtMalgruppe(values).undertypeErObligatorisk ||
            Object.keys(input).filter((i) => input[i]).length > 0,
          message: intl.formatMessage({ id: "validation.oppgiMinstEttAlternativ" }),
        },
      ],
    },
    [FieldName.BEKREFTREPRESENTASJON]: {
      value: false,
      validation: [
        {
          validation: (input, values) =>
            !malgruppeHasValue(values) ||
            !bekreftRepresentasjonRequired(valgtMalgruppe(values)) ||
            validationFunctions.boolRequired.validation(input),
          message: (value, values) =>
            valgtMalgruppe(values).bekreftRepresentasjonFeilMelding[intl.locale]
              ? valgtMalgruppe(values).bekreftRepresentasjonFeilMelding[intl.locale]
              : intl.formatMessage(
                  { id: "sendInnPopin.bekreftRepresentasjonFeilMelding" },
                  { 0: valgtMalgruppe(values).tittel.toLowerCase() }
                ),
        },
      ],
    },
  });

  const onSuccess = () => {
    actions.sendInnForm(formValidation.values, uttalelse, hoering, userMalform);
  };

  return (
    <Popin className="SendInnPopin" id={id} title={popinTitle} cancelButton={false}>
      <SendInnForm
        popinId={id}
        {...rest}
        {...formValidation}
        representererEnkeltperson={representererEnkeltperson}
        setRepresenterer={setRepresenterer}
        hoering={hoering}
        onSuccess={onSuccess}
      />
    </Popin>
  );
};

SendInnPopin.propTypes = {
  id: PropTypes.string.isRequired,
  hoering: hoeringShape.isRequired,
  ingenMerknader: PropTypes.bool,
  uttalelse: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  eksternId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    hoering: state.hoering,
    ingenMerknader: state.uttalelse.ingenMerknader,
    uttalelse: state.uttalelse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(serverActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendInnPopin);
